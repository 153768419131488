import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {selectDownloadedFiles, selectPatientDocuments, selectPubmenStudy} from "store/features/newRequestSlice";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {selectDoctorValues} from "../doctor/doctorSlice";
import {selectMoreInformationValues} from "../med/AdditionalInformation/forms/DefaultMedicineForm/defaultMedicineSlice";
import {selectPraluentValues} from "../med/AdditionalInformation/forms/PraluentForm/praluentSlice";
import {selectSpecialAdditionalInformation, selectSpecialMedType, SpecialMedAdditionalInformation} from "../med/AdditionalInformation/specialMedSlice";
import {selectDiagnosisValues} from "../med/Diagnosis/diagnosisSlice";
import {selectDosisValues} from "../med/Dosis/dosisPlanSlice";
import {selectMedicineValues} from "../med/medicine/medicineSlice";
import {selectTreatmentValues} from "../med/Treatment/treatmentPlanSlice";
import {selectPatientValues} from "../patient/patientSlice";
import {OverviewRow} from "./components/OverviewRow";
import {handleDownloadPdf} from "./components/utils";
import {onViewChange, saveOrEdit, isFormVisible, sendRequestToInsurance, cleanStore} from "pages/Wizard/wizardSlice";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import {selectBrukinsaValues} from "../med/AdditionalInformation/forms/BrukinsaForm/BrukinsaSlice";
import {selectQuviviqValues} from "../med/AdditionalInformation/forms/QuviviqForm/QuviviqSlice";
import {selectEnhertuValues} from "../med/AdditionalInformation/forms/EnhertuForm/EnhertuSlice";
import {selectCalquenceValues} from "../med/AdditionalInformation/forms/Calquence/CalquenceSlice";
import {selectImbruvicaValues} from "../med/AdditionalInformation/forms/Imbruvica/ImbruvicaSlice";
import {selectImfinziValues} from "../med/AdditionalInformation/forms/Imfinzi/ImfinziSlice";
import {selectImjudoValues} from "../med/AdditionalInformation/forms/Imjudo/ImjudoSlice";
import {selectTagrissoValues} from "../med/AdditionalInformation/forms/Tagrisso/TagrissoSlice";
import {selectLynparzaValues} from "../med/AdditionalInformation/forms/Lynparza/LynparzaSlice";
import {selectIbranceValues} from "../med/AdditionalInformation/forms/Ibrance/IbranceSlice";
import {selectSomatulineValues} from "../med/AdditionalInformation/forms/Somatuline/SomatulineSlice";
import {selectCabometyxValues} from "../med/AdditionalInformation/forms/Cabometyx/CabometyxSlice";
import {selectSpravatoValues} from "../med/AdditionalInformation/forms/Spravato/SpravatoSlice";
import {selectTecvayliValues} from "../med/AdditionalInformation/forms/Tecvayli/TecvayliSlice";
import {selectTalveyValues} from "../med/AdditionalInformation/forms/Talvey/TalveySlice";
import {selectDarzalexValues} from "../med/AdditionalInformation/forms/Darzalex/DarzalexSlice";
import {saveRequestBodyToSession} from "pages/Wizard/wizardSlice";
import {login} from "./../../../../config/msalProvider";
import {LinearProgressBar} from "./components/LinearProgressBar";
import {formatDateGerman} from "components/formatting";
import {selectIsAuthenticated} from "store/features/userSlice";
import {useParams, useHistory, useLocation} from "react-router-dom";
import {invitePatient, requestStudiesReq} from "store/middleware/newRequestThunk";

import PendingInsuranceDialog from "components/dialogs/PendingInsuranceDialog";
import {additionalInfomationRows} from "./components/AdditionalInformationRows";
import {selectBrukinsaCllValues} from "../med/AdditionalInformation/forms/BrukinsaFormCll/BrukinsaCllSlice";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import {sendSetReminder} from "store/middleware/requestListThunk";
import {AccountCreate} from "./components/AccountCreate";

export const Overview = (props) => {
  const {t, i18n}=useTranslation();
  const history=useHistory();
  const currentLang=i18n.language.toUpperCase();
  const dispatch=UseAppDispatch();
  const isVisibleValues = UseAppSelector(isFormVisible);
  const {requestId} = useParams<{requestId:string}>();
  const isAuthenticated =UseAppSelector(selectIsAuthenticated);
  const patientValues=UseAppSelector(selectPatientValues);
  const doctorValues=UseAppSelector(selectDoctorValues);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const diagnosisValues=UseAppSelector(selectDiagnosisValues);
  const treatmentValues=UseAppSelector(selectTreatmentValues);
  const dosisValues=UseAppSelector(selectDosisValues);
  const pubmedStudy=UseAppSelector(selectPubmenStudy);
  const praluentValues=UseAppSelector(selectPraluentValues);
  const brukinsaValues=UseAppSelector(selectBrukinsaValues);
  const brukinsaCllValues=UseAppSelector(selectBrukinsaCllValues);
  const quviviqValues = UseAppSelector(selectQuviviqValues);
  const calquenceValues = UseAppSelector(selectCalquenceValues);
  const imfinziValues = UseAppSelector(selectImfinziValues);
  const imjudoValues = UseAppSelector(selectImjudoValues);
  const tagrissoValues = UseAppSelector(selectTagrissoValues);
  const lynparzaValues = UseAppSelector(selectLynparzaValues);
  const ibranceValues = UseAppSelector(selectIbranceValues);
  const somatulineValues = UseAppSelector(selectSomatulineValues);
  const cabometyxValues = UseAppSelector(selectCabometyxValues);
  const spravatoValues = UseAppSelector(selectSpravatoValues);
  const tecvayliValues = UseAppSelector(selectTecvayliValues);
  const talveyValues = UseAppSelector(selectTalveyValues);
  const darzalexValues = UseAppSelector(selectDarzalexValues);
  const imbruvicaValues = UseAppSelector(selectImbruvicaValues);
  const enhertuValues=UseAppSelector(selectEnhertuValues);
  const patientDocuments=UseAppSelector(selectPatientDocuments);
  const downloadedDocuments=UseAppSelector(selectDownloadedFiles);
  const specialMed=UseAppSelector(selectSpecialMedType);
  const specialAdditionalInfo=UseAppSelector(selectSpecialAdditionalInformation);
  const additionalInformationValues=UseAppSelector(selectMoreInformationValues);
  const additionalInformation=UseAppSelector(selectSpecialAdditionalInformation);
  const patientName=patientValues?.patient_surename+" "+patientValues?.patient_lastname;
  const doctorName=(doctorValues?.doctor_title || "") +" "+doctorValues?.doctor_surename+" "+doctorValues?.doctor_lastname;

  const params=useLocation();
  const [openInsuranceDialog, setOpenInsuranceDialog]=useState(false);

  const mailBody=t("mailto.body", {
    patient_name_and_social_id: patientName + " AHV: " + patientValues?.patient_insurance_no,
    title_fullname_doctor: doctorName});
  const mailSubject=t("mailto.subject", {requestType: medicineValues.request_type});

  const pubMedStudyAuthors=Array.isArray(pubmedStudy?.authors)?pubmedStudy?.authors
      ?.map((e)=>e?.name)
      .join(", "):pubmedStudy?.authors;
  const documentsForPdf= [...downloadedDocuments, ...patientDocuments]?.map((e)=>e?.name+" - "+t(`upload.document.${e?.uploadtype}`)).join(",\n");

  const medValuesForPdf={...diagnosisValues, ...medicineValues,
    ...dosisValues, ...treatmentValues, ...additionalInformationValues,
    ...(pubmedStudy?.pmid&&{studies: [{...pubmedStudy, authors: pubMedStudyAuthors}]}),
    documents: documentsForPdf};


  async function handleSaveRequestFunction(insurance = false, studies = false, comment = "") {
    const isCopy=params?.pathname?.includes("copy_request");
    const isSpravato = specialMed === "Spravato";
    const isPraluent = specialMed === "Praluent";
    const regex = /\d+$/;
    const isEditId = regex.test(params.pathname);

    console.debug("handleSaveRequestFunction",
        "requestid:", requestId,
        "isCopy:", isCopy,
        "isSpravato:", isSpravato,
        "isPraluent:", isPraluent,
        "isEditId:", isEditId);

    let requestId_param= "";
    if (isEditId) {
      requestId_param = requestId;
    }
    if (isCopy) {
      requestId_param = "";
    }

    await dispatch(saveOrEdit({language: currentLang, requestId: requestId_param})).then(async (res)=>{
      requestId_param = res?.payload[0]?.newRequestID || res?.payload[0]?.request_id;
      // Spravato and Praluent have a reminder
      if (requestId_param && (isSpravato || isPraluent) && !isEditId) {
        const remindermsg = isSpravato ? t("Reminder.Spravato") : isPraluent ? t("Reminder.Praluent") : "";
        const date = new Date();
        if (specialMed.includes("Spravato")) date.setDate(date.getDate() + 14);
        else if (specialMed.includes("Praluent")) date.setMonth(date.getMonth() + 6);
        const dateString = date.toISOString().split("T")[0];
        await Promise.all([
          dispatch(sendSetReminder(requestId_param, dateString, remindermsg)),
        ]);
      }

      // send request to insurance
      if (insurance) {
        dispatch(sendRequestToInsurance({comment: comment, requestId: requestId_param,
          currentLang: currentLang, isEdit: Boolean(requestId_param)})).then(cleanStoreFunction());
      } else if (studies) {
        await dispatch(requestStudiesReq(requestId_param)).then(cleanStoreFunction());
      } else {
        cleanStoreFunction();
      }
    });
  }

  async function cleanStoreFunction() {
    await dispatch(cleanStore({}));
    history.push("/requests");
  }

  const handleSaveRequest = async ()=>{
    await handleSaveRequestFunction();
  };

  const sendToInsurance = async (comment)=>{
    await handleSaveRequestFunction(true, false, comment);
  };

  const requestStudies = async () => {
    await handleSaveRequestFunction(false, true, "");
  };


  const handleCreateAccount = ()=>{
    Promise.all([
      dispatch(saveRequestBodyToSession({ }) ),
    ]).then((res)=>{
      login();
    });
  };
  const handleInvitePatient = ()=>{
    dispatch(saveOrEdit({language: currentLang, requestId: requestId}))
        .then(async (response) => {
          if (response?.payload && response?.payload[0]) {
            await dispatch(
                invitePatient(Boolean(requestId)?response?.payload[0]?.
                    request_id:
                  response?.payload[0]?.newRequestID,
                currentLang));
          }
          return response;
        }).then((res)=>{
          if (res?.payload[0]?.newRequestID||res?.payload[0]?.request_id) {
            history.push("/requests");
          }
        });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handlePrintPdf =async () => {
    const pdfBody = {
      // ...medValuesForPdf,
      ...(pubmedStudy?.pmid&&{studies: [{...pubmedStudy, authors: pubMedStudyAuthors}]}),
      ...patientValues,
      ...doctorValues,
      "patient_email": patientValues.patient_email_addr,
      "doctor_country": doctorValues.doctor_country.code,
      "patient_country": patientValues.patient_country.code,
      "patient_insurance_company": patientValues.patient_insurance?.DESCR1,
      "patient_gender": patientValues.patient_gender&&parseInt(patientValues.patient_gender),
      "patient_insurance_company_gln": patientValues.patient_insurance?.GLN,
      "kogu_type": "med",
      "special_med_type": specialMed,
      "diagnosis": medValuesForPdf.diagnosis,
      "remarks_diagnosis": medValuesForPdf.remarks_diagnosis,
      "urgent": medValuesForPdf.urgent,
      "required_drug": medValuesForPdf.required_drug,
      "request_type": medValuesForPdf.request_type,
      "pharma_email": medValuesForPdf.pharma_email,
      "pharma_company_name": medValuesForPdf.pharma_company_name,
      "required_drug_name": medValuesForPdf.required_drug_name,
      "kn_id": medValuesForPdf.kn_id,
      "limitatioInSLavailable": medValuesForPdf.limitatioInSLavailable,
      "medicationHasIndicationCodes": medValuesForPdf.medicationHasIndicationCodes,
      "medicationHasLimitations": medValuesForPdf.medicationHasLimitations,
      "medicationHasPricingModel": medValuesForPdf.medicationHasPricingModel,
      "medicationHasProfessionalPatientInformation": medValuesForPdf.medicationHasProfessionalPatientInformation,
      "medicationHasShortageWarning": medValuesForPdf.medicationHasShortageWarning,
      "medicationNeedsKoGuRequest": medValuesForPdf.medicationNeedsKoGuRequest,
      "inSL": medValuesForPdf.inSL,
      "tit": medValuesForPdf.lit,
      "lastupdated": medValuesForPdf.lastupdated,
      "patient_treatment_startdate": medValuesForPdf.patient_treatment_startdate,
      "dosis": medValuesForPdf.dosis,
      "combined_drug": medValuesForPdf.combined_drug,
      "limitatio_approval": medValuesForPdf.limitatio_approval,
      "specialist_details": medValuesForPdf.specialist_details,
      "specialist_info": medValuesForPdf.specialist_info,
      "pretreatment": medValuesForPdf.pretreatment,
      "patient_condition": medValuesForPdf.patient_condition,
      "disease_prognosis": medValuesForPdf.disease_prognosis,
      "therapy_alternatives": medValuesForPdf.therapy_alternatives,
      "documents": medValuesForPdf.documents,
      "med_data": {
        "special_med_type": specialMed,
        "special_med_additional_information": specialAdditionalInfo,
        "praluent_data": praluentValues,
        "brukinsa_data": brukinsaValues,
        "brukinsa_cll_data": brukinsaCllValues,
        "quviviq_data": quviviqValues,
        "calquence_data": calquenceValues,
        "imfinzi_data": imfinziValues,
        "imjudo_data": imjudoValues,
        "tagrisso_data": tagrissoValues,
        "lynparza_data": lynparzaValues,
        "ibrance_data": ibranceValues,
        "somatuline_data": somatulineValues,
        "cabometyx_data": cabometyxValues,
        "imbruvica_data": imbruvicaValues,
        "enhertu_data": enhertuValues,
        "spravato_data": spravatoValues,
        "tecvayli_data": tecvayliValues,
        "talvey_data": talveyValues,
        "darzalex_data": darzalexValues,
        "diagnosis": medValuesForPdf.diagnosis,
        "article_prdno": medValuesForPdf.article_prdno,
      },
      "migel_data": {},
      "ambstat_data": {},
      "reha_data": {},
      "transport_data": {},
      "breathing_device_data": {},
      "correspondence_language": currentLang,
    };
    if (isAuthenticated === false) {
      handleOpenModal();
    }
    console.debug("pdfBody", pdfBody);
    dispatch(handleDownloadPdf(currentLang, pdfBody));
  };
  const showProgressBar=useMediaQuery( "(max-height:1000px)");

  const isVisible=useIsInViewport(props.refProps);
  useEffect(()=>{
    isVisibleValues.overview!==isVisible&&dispatch(onViewChange({field: "overview", value: isVisible}));
  }, [isVisible]);

  useEffect(()=>{
  }, [downloadedDocuments, pubmedStudy]);

  const showAdditionalInformation = () => {
    console.debug("Overview specialMed", specialMed);
    console.debug("Overview additionalInformationValues", additionalInformationValues);

    if (
      !additionalInformationValues?.pretreatment &&
      !additionalInformationValues?.disease_prognosis &&
      !additionalInformationValues?.patient_condition &&
      !additionalInformationValues?.therapy_alternatives
    ) {
      return false;
    };
    return true;
  };


  return (
    <Grid ref={props.refProps} container direction="column" spacing={3} marginTop='3px' id="overview" data-cy="overview" >

      <PendingInsuranceDialog
        isOpen={openInsuranceDialog}
        handleClose={()=>setOpenInsuranceDialog(false)}
        handleSend={(comment)=>sendToInsurance(comment)}
        requestData={patientValues}
      />
      <Grid item container xs={12} spacing={2}>
        <Grid item style={{"borderLeft": "7px solid #63c2de", "paddingTop": 0}}>
          <Typography variant="h5" >
            {`${t(props.label)}`}
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Typography variant="h6" fontWeight='bold'>
            {t("wizard.overview.patient.data")}
          </Typography>
        </Grid>
        <Grid item container spacing={2}>
          <OverviewRow rowTitle={t("wizard.name")} rowContent={
            (patientName && patientName.length > 1) ? patientName : "-"
          } />
          <OverviewRow rowTitle={t("patient.label.dob")}
            rowContent={
              formatDateGerman(patientValues?.patient_date_of_birth) || "-"
            }
          />
          <OverviewRow rowTitle={t( "patient.label.phone.no")} rowContent={patientValues?.patient_phone_no||"-"}/>
          <OverviewRow rowTitle={t( "patient.label.email")} rowContent={patientValues?.patient_email_addr||"-"}/>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} >
            <Typography fontWeight='bold'>{t("wizard.address.data")}</Typography>
          </Grid>
          <OverviewRow rowTitle={t("wizard.street.housenumber")} rowContent={patientValues?.patient_street||"-"}/>
          <OverviewRow rowTitle={t("wizard.city")} rowContent={patientValues?.patient_city || "-"}/>
          <OverviewRow rowTitle={t("wizard.city.zipcode")}
            rowContent={
              (patientValues?.patient_country?.code || "") +
              " " +
              (patientValues?.patient_postalcode || "-")
            }
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} >
            <Typography fontWeight='bold'>{t("wizard.insurance.data")}</Typography>
          </Grid>
          <OverviewRow rowTitle={t("patient.label.insurance.no")} rowContent={patientValues?.patient_insurance_no||"-"}/>
          <OverviewRow rowTitle={t( "patient.label.insurance.company")} rowContent={patientValues?.patient_insurance?.DESCR1||"-"}/>
        </Grid>
        <Grid item xs={12} marginY={2}>
          <Typography variant="h6" fontWeight='bold'>
            {t("wizard.overview.doctor.data")}
          </Typography>
        </Grid>
        <Grid item container spacing={2}>
          <OverviewRow rowTitle={t("wizard.name")} rowContent={doctorName}/>
          <OverviewRow rowTitle={t( "patient.label.phone.no")} rowContent={doctorValues?.doctor_phone_no||"-"}/>
          <OverviewRow rowTitle={t( "doctor.details.fax.no")} rowContent={doctorValues?.doctor_fax_no||"-"}/>
          <OverviewRow rowTitle={t( "patient.label.email")} rowContent={doctorValues?.doctor_email_addr||"-"}/>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} >
            <Typography fontWeight='bold'>{t("wizard.address.data")}</Typography>
          </Grid>
          <OverviewRow rowTitle={t("doctor.details.hospital")} rowContent={doctorValues?.doctor_center_name|| ""}/>
          <OverviewRow rowTitle={t("wizard.street.housenumber")} rowContent={doctorValues?.doctor_street||"-"}/>
          <OverviewRow rowTitle={t("wizard.city")} rowContent={doctorValues?.doctor_city||"-"}/>
          <OverviewRow rowTitle={t( "wizard.city.zipcode")} rowContent={doctorValues?.doctor_country?.code + " "+ doctorValues?.doctor_postalcode}/>
        </Grid>
        <Grid item xs={12} marginY={2}>
          <Typography variant="h6" fontWeight='bold'>
            {t("wizard.overview.application.data")}
          </Typography>
        </Grid>
        <Grid item container spacing={2}>
          {diagnosisValues?.diagnosis &&
            <OverviewRow rowTitle={t("studieRequest.patient.diagnose")} rowContent={diagnosisValues?.diagnosis||"-"}/>
          }
          {diagnosisValues?.remarks_diagnosis &&
          <OverviewRow rowTitle={t( "indication.details.remarks.diagnosis")} rowContent={diagnosisValues?.remarks_diagnosis||"-"}/>
          }
          {diagnosisValues?.urgent &&
          <OverviewRow rowTitle={t( "printkogu.urgent")} rowContent={diagnosisValues?.urgent?t("indication.yes"):t("indication.no")}/>
          }
          {medicineValues?.required_drug &&
          <OverviewRow rowTitle={t( "requestlist.headers.therapy")} rowContent={medicineValues?.required_drug||"-"}/>
          }
          {medicineValues?.kn_id &&
          <OverviewRow rowTitle={t("indication.details.type.limitationId")} rowContent={medicineValues?.kn_id||"-"}/>
          }
          <OverviewRow rowTitle={t("fach.limitation")} rowContent={treatmentValues?.limitatio_approval?t("indication.yes"):t("indication.no")}/>
          <OverviewRow rowTitle={t("limitation.details")} rowContent={treatmentValues?.specialist_info?t("indication.yes"):t("indication.no")}/>
          <OverviewRow rowTitle={t("requestlist.headers.request.type")} rowContent={medicineValues.request_type||"-"}/>
        </Grid>
        <Grid item xs={12} >
          <Typography fontWeight='bold'>{t("wizard.overview.dosis.data")}</Typography>
        </Grid>

        <Grid item container spacing={2}>
          <OverviewRow rowTitle={t( "startdate.treatment")} rowContent={dosisValues?.patient_treatment_startdate?.toString()!=="Invalid Date"?formatDateGerman( dosisValues?.patient_treatment_startdate):"-"}/>
          <OverviewRow rowTitle={t( "indication.details.dosis")} rowContent={dosisValues?.dosis||"-"}/>
          <OverviewRow rowTitle={t("indication.details.combined.drug")} rowContent={dosisValues?.combined_drug?.toString().replaceAll(",", ", ")||"-"}/>
        </Grid>
        {showAdditionalInformation()&&<>
          <Grid item xs={12} >
            <Typography fontWeight='bold'> {t("wizard.overview.additionalInformation.data")}</Typography>
          </Grid>
          {specialMed === "Enhertu" &&
          <Grid item container spacing={2}>
            <OverviewRow rowTitle={t("enhertu.overview.sent.mail")} rowContent=
              {t(`enhertu.overview.attachment_${SpecialMedAdditionalInformation[additionalInformation]}`)}/>
          </Grid>
          }


          {/* data for speacial meds  */}
          {additionalInfomationRows(specialMed, {
            brukinsa: brukinsaValues,
            brukinsaCll: brukinsaCllValues,
            praluent: praluentValues,
            quviviq: quviviqValues,
            calquence: calquenceValues,
            imfinzi: imfinziValues,
            imjudo: imjudoValues,
            tagrisso: tagrissoValues,
            lynparza: lynparzaValues,
            ibrance: ibranceValues,
            somatuline: somatulineValues,
            cabometyx: cabometyxValues,
            spravato: spravatoValues,
            imbruvica: imbruvicaValues,
            tecvayli: tecvayliValues,
            talvey: talveyValues,
            darzalex: darzalexValues,
            defaultMedicine: additionalInformationValues,
          })}
        </>}
        <Grid item xs={12} marginY={2}>
          <Typography variant="h6" fontWeight='bold'>
            {t("wizard.overview.studies.documents.data")}
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <Typography fontWeight='bold'> {t("wizard.overview.added.studies")}</Typography>
        </Grid>
        {pubmedStudy?.title?<Grid item container spacing={2}>
          <OverviewRow rowTitle={t("pubmed.label.title")} rowContent={pubmedStudy?.title||"-"}/>
          <OverviewRow rowTitle={
            pubmedStudy?.doi?"DOI":
            pubmedStudy?.nct?"NCT":
            pubmedStudy?.pmcid?"PMCID":
            pubmedStudy?.pmid?"PMID":""
          } rowContent={
            pubmedStudy?.doi ||
            pubmedStudy?.nct ||
            pubmedStudy?.pmcid ||
            pubmedStudy?.pmid || ""
          }/>
        </Grid>:<Grid item xs={12}><Typography>{t("no.pubmed.study.attached")}</Typography></Grid>}
        <Grid item xs={12} >
          <Typography fontWeight='bold'> {t("wizard.overview.added.documents")}</Typography>
        </Grid>
        <Grid item container spacing={2}>
          {patientDocuments?.length>0?patientDocuments.map((document)=>(
            <span key={Math.random() *10000} className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-1tw2i9j-MuiGrid-root">
              <OverviewRow rowTitle={t("doctor.details.title")} rowContent={document?.name||"-"}/>
              <OverviewRow rowTitle={t("requestlist.headers.date")} rowContent={formatDateGerman(new Date(document?.lastModified))||"-"}/>
              <OverviewRow rowTitle={t("pubmed.label.type")} rowContent={document?.uploadtype?t(`upload.document.${document.uploadtype}`):"-"}/>
            </span>
          )):[]}
        </Grid>
        <Grid item container spacing={2}>
          {downloadedDocuments?.length>0?downloadedDocuments.map((document)=>(
            <span key={Math.random() *10000} className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-1tw2i9j-MuiGrid-root">
              <OverviewRow rowTitle={t("doctor.details.title")} rowContent={document?.name||"-"}/>
              <OverviewRow rowTitle={t("pubmed.label.type")} rowContent={document.uploadtype?t(`upload.document.${document.uploadtype}`):"-"}/>
            </span>
          )):[]}
        </Grid>
        {!(downloadedDocuments?.length>0)&&!(patientDocuments?.length>0)&&<Grid item xs={12}><Typography>{t("no.documents.attached")}</Typography></Grid>}
        <Grid item container xs={12}>
          <Typography>
            {t("information.for.unauthenticated.user")}
          </Typography>
        </Grid>
        {showProgressBar&&<Grid item container xs={12}>
          <LinearProgressBar/>
        </Grid>}

        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
            hidden= {( patientValues.patient_agreement === true ) }
          >
            <WarningIcon color={"warning"}></WarningIcon>
            <Typography>{t("newrequest.missing.patientAgreement")}</Typography>
          </Stack>
          <Box sx={{m: 1}} hidden= {( patientValues.patient_insurance?.email?.length > 3 ) }></Box>
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
            hidden= {( patientValues.patient_insurance?.email?.length > 3 ) }
          >
            <WarningIcon color={"warning"}></WarningIcon>
            <Typography>{t("newrequest.missing.insurance")}</Typography>
          </Stack>
          <Box sx={{m: 1}} hidden= {( medicineValues.pharma_email?.length > 3 ) }></Box>
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
            hidden= {( medicineValues.pharma_email?.length > 3 ) }
          >
            <WarningIcon color={"warning"}></WarningIcon>
            <Typography>{t("newrequest.missing.pharmamail")}</Typography>
          </Stack>
          <Box sx={{m: 1}} hidden= {( !isAuthenticated || (isAuthenticated === true && props.verified) ) }></Box>
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
            hidden= {( !isAuthenticated || (isAuthenticated === true && props.verified) ) }
          >
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("newrequest.missing.verified")}</Typography>
          </Stack>

        </Grid>


        <Grid item container xs={12} justifyContent="space-between" spacing={3} data-cy="get_pdf">
          <Grid item>
            <Button
              onClick={handlePrintPdf } >
              {t("wizard.overview.generate.pdf")}
            </Button>
          </Grid>

          <Grid item sx={{display: isAuthenticated === true?"inherit":"none"}} data-cy="save_request">
            <Button
              onClick={handleSaveRequest } >
              {t("newrequest.label.saveAsNew")}
            </Button>
          </Grid>
          <Grid item sx={{display: isAuthenticated === false?"inherit":"none"}} data-cy="ceate_account">
            <Button
              onClick={handleCreateAccount } >
              {t("newrequest.label.createAccount")}
            </Button>
          </Grid>

          <AccountCreate open={openModal}></AccountCreate>

          <Grid
            hidden= {( !medicineValues.required_drug?.includes("SAXENDA")) }
            item sx={{display: isAuthenticated === true?"inherit":"none"}} data-cy="invite_patient">
            <Button
              onClick={handleInvitePatient }
              disabled={!medicineValues.required_drug?.includes("SAXENDA")||patientValues?.patient_email_addr===""}>
              {t("newrequest.label.invite.patient")}
            </Button>
          </Grid>

          <Grid item sx={{display: isAuthenticated === true?"inherit":"none"}} data-cy="study_request">
            <Button
              onClick={requestStudies }
              disabled={
                isAuthenticated !== true ||
                props.verified !== true ||
                !medicineValues.pharma_email ||
                !patientValues.patient_agreement
              }>
              {t("newrequest.label.studies")}
            </Button>
          </Grid>
          <Grid item data-cy="sent_to_insurance">
            <Button variant="contained"
              disabled={
                patientValues.patient_insurance?.email?.length <= 3 ||
                patientValues.patient_agreement === false ||
                (isAuthenticated === false) ||
                (!props.verified)

              }
              onClick={
                (isAuthenticated === true && props.verified) ?
                ()=>setOpenInsuranceDialog(true):
                ()=>window
                    .open(`mailto:${patientValues.patient_insurance?.email}
                ?subject=${mailSubject}&body=${encodeURIComponent(mailBody)}`)
              }>
              {t("send.to.insurance")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

    </Grid>

  );
};

const mapStateToProps = (state) => {
  return {
    verified: state.userinformation?.user?.groups?.includes("verified") === true,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
