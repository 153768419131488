import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";
export interface IMedStateDrug {
  required_drug:string;
  request_type:string;
  pharma_email: string;
  pharma_company_name: string;
  required_drug_name:string;
  kn_id:string;
  article_prdno:string;
  pharmacode:string;
}

export interface IMedStateSameSubstanceMeds {
  SUBNO:number;
  PRDNO:string;
  NAML:string;
  NAMF:string;
  NAMD:string;
  DSCRD:string;
  DSCRF:string;
  medicationShortageWarning:string
  PRICE:number;
  PTYP:string;
  PHARMACODE:string;
}

export interface IMedStateSubstance {
  NAMF:string;
  NAMD:string;
}

export interface IMedStateInteraction {
  TITD:string; // Title
  TITF:string; // Title
  EFFTXTD:string;
  EFFTXTF:string;
  MEASD:string;
  MECHD:string;
  MEASF:string;
  MECHF:string;
  RLV:Number
}

export interface IMedStateLimtree {
  PHARMACODE: string,
  PRDNO: string,
  LIMCD: string,
  INDCDSTRING: string,
  LIMITATIONSGERMAN: string,
  LIMITATIONSFRENCH: string,
  VDAT: string,
  LIMTYP: string
}

export interface IMedState {
  formData:IMedStateDrug;
  htmlData:{
    professionalPatientInformationInHtmlFormat:string
    limtree : Array<IMedStateLimtree>
  },
  sameSubstanceMeds:{
    sameSubstanceMeds:Array<IMedStateSameSubstanceMeds>
  },
  shortage:{
    medicationShortageWarning: any,
  }
  substances: {
    substances:Array<IMedStateSubstance>
  }
  productsInteraction: {
    productsInteraction:Array<IMedStateInteraction>,
  }
  lastupdated: {
    lastupdated: string,
  },
  lastloaded: {
    lastloaded: number,
  },
  indicationcodes : {
    indicationcodes:Array<string>
  },
  legal: {
    limitatioInSLavailable: boolean
    medicationHasIndicationCodes: boolean
    medicationHasLimitations: boolean
    medicationHasPricingModel: boolean
    medicationHasProfessionalPatientInformation: boolean
    medicationHasShortageWarning: boolean
    medicationNeedsKoGuRequest: boolean
    inSL: boolean
  },
  codex: {
    chr:string,
    cind:string,
    cmp:string,
    ind:string,
    pos:string,
    tit:string,
  },

}

const initialState:IMedState = {
  // the part we sent to the insurance
  formData: {
    required_drug: "",
    request_type: "",
    pharma_email: "",
    pharma_company_name: "",
    required_drug_name: "",
    article_prdno: "",
    kn_id: "",
    pharmacode: "",
  },
  // the part we need for business logic and display to the user
  sameSubstanceMeds: {
    sameSubstanceMeds: [],
  },
  substances: {
    substances: [],
  },
  htmlData: {
    professionalPatientInformationInHtmlFormat: "",
    limtree: [],
  },
  shortage: {
    medicationShortageWarning: "",
  },
  productsInteraction: {
    productsInteraction: [],
  },
  lastupdated: {
    lastupdated: "",
  },
  lastloaded: {
    lastloaded: 0,
  },
  indicationcodes: {
    indicationcodes: [],
  },
  legal: {
    limitatioInSLavailable: false,
    medicationHasIndicationCodes: false,
    medicationHasLimitations: false,
    medicationHasPricingModel: false,
    medicationHasProfessionalPatientInformation: false,
    medicationHasShortageWarning: false,
    medicationNeedsKoGuRequest: false,
    inSL: false,
  },
  codex: {
    chr: "",
    cind: "",
    cmp: "",
    ind: "",
    pos: "",
    tit: "",
  },
};

const medicineSlice = createSlice({
  name: "medicine",
  initialState,
  reducers: {
    onReceiveMedEditData: (state, {payload}:PayloadAction<any>)=>{
      console.debug("onReceiveMedEditData", payload);
      state.formData ={...state.formData, ...payload};
    },

    onChangeIndicationCodesValue: (state, {payload}:PayloadAction<{value:any;}>)=>{
      console.debug("onChangeIndicationCodesValue", payload);
      if (payload.value && payload.value.length > 1) {
        const indicationcodes = payload.value.split(" ");
        const noEmptyStringsArray = indicationcodes.filter((str) => str !== "");
        state.indicationcodes["indicationcodes"] = noEmptyStringsArray;
      }
    },
    onChangeLegalValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      console.debug("onChangeLegalValue", payload);
      state.legal[payload.field] = payload.value;
    },

    onChangeMedicineValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      console.debug("onChangeMedicineValue", payload.field, payload.value);
      state.formData[payload.field] = payload.value;
    },
    onChangeSameSubstanceValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      console.debug("onChangeSameSubstanceValue", payload);
      state.sameSubstanceMeds[payload.field] = payload.value;
    },
    onChangeHtmlValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      console.debug("onChangeHtmlValue", payload);
      state.htmlData[payload.field] = payload.value;
    },
    onChangeShortageValue: (state, {payload}:PayloadAction<{value:any;}>)=>{
      state.shortage["medicationShortageWarning"] = payload.value;
    },
    onChangeSubstancesValue: (state, {payload}:PayloadAction<{value:any;}>)=>{
      console.debug("onChangeSubstancesValue", payload);
      state.substances["substances"] = payload.value;
    },

    onClearSubstancesValue: (state)=>{
      state.substances = initialState.substances;
    },

    onChangeLastUpdated: (state, {payload}:PayloadAction<{value:any;}>)=>{
      console.debug("onChangeLastUpdated", payload);
      state.lastupdated["lastupdated"] = payload.value;
    },
    onChangeLastLoaded: (state)=>{
      state.lastloaded.lastloaded = Date.now();
    },

    onClearMedicineState: (state)=>{
      state.formData=initialState.formData;
      state.sameSubstanceMeds=initialState.sameSubstanceMeds;
      state.htmlData=initialState.htmlData;
      state.shortage.medicationShortageWarning=initialState.shortage.medicationShortageWarning;
      state.lastupdated["lastupdated"] = "";
      state.substances = initialState.substances;
      state.shortage = initialState.shortage;
      state.legal = initialState.legal;
      state.codex = initialState.codex;
      state.indicationcodes = initialState.indicationcodes;
      state.productsInteraction = initialState.productsInteraction;
      state.lastloaded.lastloaded = Date.now();
    },
    onChangeInteractionsValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      console.debug("onChangeInteractionsValue", payload);
      state.productsInteraction["productsInteraction"] = payload.value;
    },
    onChangeMedicationValues: (state, {payload}:PayloadAction<{value:any;}>)=>{
      if (payload.value) {
        try {
          state.productsInteraction["productsInteraction"] = payload.value.interactionsOfProduct;
          state.lastupdated["lastupdated"] = payload.value.lastupdated;

          state.substances["substances"] = payload.value.substances;
          state.shortage["medicationShortageWarning"] = payload.value.medicationShortageWarning;
          state.htmlData["limtree"] = payload.value.limtree;

          if (payload.value.professionalPatientInformationInHtmlFormat !== undefined) {
            let html = payload.value.professionalPatientInformationInHtmlFormat;
            html = html.replaceAll("<b><em>", "<b><em><br>");
            state.htmlData["professionalPatientInformationInHtmlFormat"] = html;
          }
          // indicationcodes master data part
          state.sameSubstanceMeds["sameSubstanceMeds"] = payload.value.sameSubstanceMedications;
          if (payload.value && payload.value.kn_id?.length > 1) {
            const indicationcodes = payload.value.kn_id.split(",");
            const noEmptyStringsArray = indicationcodes.filter((str) => str !== "");
            state.indicationcodes["indicationcodes"] = noEmptyStringsArray;
          } else {
            console.debug("no_indicationcodes");
            state.indicationcodes["indicationcodes"] = [];
          }
          state.formData["required_drug"] = payload.value.article;
          state.formData["pharma_email"] = payload.value.pharmaCompanyMailAdress;
          state.formData["pharma_company_name"] = payload.value.prtnoPharmaCompany;
          state.formData["required_drug_name"] = payload.value.productname;
          state.formData["article_prdno"] = payload.value.article_prdno;
          state.formData["pharmacode"] = payload.value.pharmacode;

          state.legal["inSL"] = payload.value.inSL;
          state.legal["medicationHasIndicationCodes"] = payload.value.medicationHasIndicationCodes === true;
          state.legal["medicationHasLimitations"] = payload.value.medicationHasLimitations;
          state.legal["medicationHasProfessionalPatientInformation"] = payload.value.medicationHasProfessionalPatientInformation;
          state.legal["medicationHasShortageWarning"] = payload.value.medicationHasShortageWarning;
          state.legal["medicationNeedsKoGuRequest"] = payload.value.medicationNeedsKoGuRequest;
          state.legal["medicationHasPricingModel"] = payload.value.medicationHasPricingModel;

          state.codex["chr"] =payload.value.CHR;
          state.codex["cind"] =payload.value.CIND;
          state.codex["cmp"] =payload.value.CMP;
          state.codex["ind"] =payload.value.IND;
          state.codex["pos"] =payload.value.POS;
          state.codex["tit"] =payload.value.TIT;

          state.lastloaded.lastloaded = Date.now();
        } catch (error) {
          console.error("onChangeMedicationValues", error);
        }
      }
    },


    onChangeKVVLegalState: (state, {payload}:PayloadAction<{
      limitatio_approval: boolean,
      specialist_info: boolean
    }>)=>{
      console.debug("onChangeKVVLegalState");
      console.debug("[onChangeKVVLegalState] KVV DECISSION",
          "", "",
          "is in SL List", state.legal.inSL,
          "has limmitatio", state.legal.medicationHasLimitations,
          "is a custom Medication", (state.substances["substances"]?.length === 0),
          "limmitatio switch is on", (payload.limitatio_approval),
          "specialist switch is on", (payload.specialist_info));
      try {
        const medicationIsinSLList = state.legal.inSL;
        const limitatioInSLavailable = state.legal.medicationHasLimitations;

        const customMedication = (
          state.substances["substances"]?.length === 0 &&
          state.formData["required_drug"]?.length > 0
        );

        const limitatioApprovalSwitch = payload.limitatio_approval;
        const specialistInfoSwitch = payload.specialist_info;
        switch (true) {
          case state.formData["required_drug"]?.length === 0:
            state.formData["request_type"] = "-";
            break;
          case customMedication:
            console.debug("[MedForm] KVV DECISSION", "71c");
            state.formData["request_type"] = "71c KVV";
            break;
          case !medicationIsinSLList:
            console.debug("[MedForm] KVV DECISSION", "71b");
            state.formData["request_type"] = "71b KVV";
            break;
          case medicationIsinSLList && !limitatioInSLavailable && specialistInfoSwitch:
            console.debug("[MedForm] KVV DECISSION", "Inlabel");
            state.formData["request_type"] = "Inlabel";
            break;
          case medicationIsinSLList && !limitatioInSLavailable && !specialistInfoSwitch:
            console.debug("[MedForm] KVV DECISSION", "71a");
            state.formData["request_type"] = "71a KVV";
            break;
          case limitatioApprovalSwitch && specialistInfoSwitch:
            console.debug("[MedForm] KVV DECISSION", "Inlabel");
            state.formData["request_type"] = "Inlabel";
            break;
          case limitatioApprovalSwitch && !specialistInfoSwitch:
            console.debug("[MedForm] KVV DECISSION", "71a");
            state.formData["request_type"] = "71a KVV";
            break;
          case !limitatioApprovalSwitch && specialistInfoSwitch:
            console.debug("[MedForm] KVV DECISSION", "71a");
            state.formData["request_type"] = "71a KVV";
            break;
          case !limitatioApprovalSwitch && !specialistInfoSwitch:
            console.debug("[MedForm] KVV DECISSION", "71a");
            state.formData["request_type"] = "71a KVV";
            break;
          default:
            state.formData["request_type"] = "n/a";
            break;
        }

        console.debug("onChangeKVVLegalState Result", state.formData["request_type"]);
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export const {
  onChangeMedicineValue,
  onChangeHtmlValue,
  onReceiveMedEditData,
  onChangeSubstancesValue,
  onChangeSameSubstanceValue,
  onClearMedicineState,
  onChangeShortageValue,
  onChangeInteractionsValue,
  onChangeLegalValue,
  onChangeIndicationCodesValue,
  onChangeLastUpdated,
  onChangeMedicationValues,
  onChangeKVVLegalState,
  onChangeLastLoaded,
  onClearSubstancesValue,
} = medicineSlice.actions;

export default medicineSlice.reducer;
export const selectMedicineValues = (state:RootState) => ({
  ...state.wizard.medicine.medForm.formData,
  ...state.wizard.medicine.medForm.htmlData,
  ...state.wizard.medicine.medForm.sameSubstanceMeds,
  ...state.wizard.medicine.medForm.shortage,
  ...state.wizard.medicine.medForm.substances,
  ...state.wizard.medicine.medForm.productsInteraction,
  ...state.wizard.medicine.medForm.legal,
  ...state.wizard.medicine.medForm.codex,
  ...state.wizard.medicine.medForm.indicationcodes,
  ...state.wizard.medicine.medForm.lastupdated,
  ...state.wizard.medicine.medForm.lastloaded,
});

