import {i18_instance} from "../i18n";
import {PublicClientApplication} from "@azure/msal-browser";
import {setAuthenticatedUserContext} from "AppInsights";
const authorityLogin = process.env.REACT_APP_AUTHORITY;

const msalConfig = {
  auth: {
    authority: authorityLogin,
    clientId: process.env.REACT_APP_CLIENT_ID,
    postLogoutRedirectUri:
    window.location.origin.replace(
        "kostengutsprache.",
        "www.")
        .replace(
            "hin."
            , "www."),
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
    knownAuthorities: [authorityLogin],
    cloudDiscoveryMetadata: "",
    clientCapabilities: ["CP1"],
  },
  cache: {
    cacheLocation: "sessionStorage", // sessionStorage will disable multi-tab support
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        console.debug("[MSAL LOG] ", message);
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 10000,
    asyncPopups: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
console.debug("[MSAL] INSTANCE CREATED");

msalInstance.addEventCallback((message) => {
  console.debug("[MSAL EVENT] ", message.eventType);
  try {
    if (JSON.stringify(message).indexOf("AADB2C90118")>0) {
      passwordEdit();
      return;
    }
    if (message.eventType === "msal:loginSuccess") {
      setAuthenticatedUserContext(message.payload.account.username); // OID
    }
    if (message.eventType === "msal:acquireTokenSuccess") {
      setAuthenticatedUserContext(message.payload.account.username); // OID
    }
    if (message.error) {
      console.debug("[MSAL] ERROR ", JSON.stringify(message));
    }
  } catch (ex) {
    console.debug("[MSAL] ERROR ", ex);
  }
});

export const acquireAccessToken = async () => {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length <= 0) {
      console.debug("[MSAL EVENT] USER NOT LOGGED IN");
      return "";
    }
    const request = {
      scopes: [
        process.env.REACT_APP_SCOPE,
      ],
      account: accounts[0],
    };
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (ex) {
    if (ex && ex.errorMessage && ex.errorMessage.indexOf("AADB2C90077") > -1) {
      console.debug("[MSAL] ERROR AADB2C90077");
      // eslint-disable-next-line
      // FIX: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2016
      try {
        msalInstance.acquireTokenRedirect(request);
      } catch (ex) {
        console.error(ex);
      }
    } else {
      // logout(); will end in an endless loop if auth errors
      console.debug("[MSAL] ERROR ", ex.errorMessage);
    }
  }
};

export const login = async () => {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      await logout();
      console.debug("[MSAL] ERROR LOGIN: ALLREADY LOGGED IN");
    } else {
      sessionStorage.setItem("ui_locales", i18_instance.i18n.language);
      await msalInstance.loginRedirect({
        scopes: [
          process.env.REACT_APP_SCOPE,
        ],
        extraQueryParameters: {
          "lang": i18_instance.i18n.language,
          "ui_locales": i18_instance.i18n.language,
        },
      }).catch( (error)=>{
        console.error("[MSAL] ERROR LOGIN: ", error);
      });
    }
  } catch (ex) {
    console.error("[MSAL] ERROR LOGIN: ", ex);
  }
};


export const logout = async () => {
  try {
    console.debug("[MSAL EVENT] LOGOUT");
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length <= 0) {
      console.debug("[MSAL EVENT] USER NOT LOGGED IN");
    } else {
      await removeCookies();
      await msalInstance.logoutRedirect();
    }
  } catch (ex) {
    console.debug("[MSAL] ERROR LOGOUT: ", ex);
    console.error(ex);
  }
};

export const logoutOn401 = async () => {
  try {
    console.debug("[MSAL EVENT] 401");
    await msalInstance.logoutRedirect();
    await removeCookies();
  } catch (ex) {
    console.debug("[MSAL] ERROR LOGOUT: ", ex);
    console.error(ex);
  }
};

export const profileEdit = async () => {
  msalInstance.loginRedirect({
    scopes: [
      process.env.REACT_APP_SCOPE,
    ],
    extraQueryParameters: {"lang": i18_instance.i18n.language},
    authority: process.env.REACT_APP_AUTHORITY_PROFILE,
  });
};

export const passwordEdit = async () => {
  msalInstance.loginRedirect({
    scopes: [
      process.env.REACT_APP_SCOPE,
    ],
    extraQueryParameters: {"lang": i18_instance.i18n.language},
    authority: process.env.REACT_APP_AUTHORITY_RESET,
  });
};

function removeCookies() {
  try {
    console.debug("[MSAL EVENT] removeCookies");
    const cookies = document.cookie.split("; ");
    eraseCookieFromAllPaths("ai_session");
    eraseCookieFromAllPaths("ai_user");
    for (let c = 0; c < cookies.length; c++) {
      if (cookies[c].startsWith("gdprDismiss")) {
        continue;
      }
      const d = window.location.hostname.split(".");
      while (d.length > 0) {
        const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
        const p = location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
  } catch (ex) {
    console.error(ex);
  }
}
function eraseCookieFromAllPaths(name) {
  // This function will attempt to remove a cookie from all paths.
  const pathBits = location.pathname.split("/");
  let pathCurrent = " path=";
  // do a simple pathless delete first.
  document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
  for (let i = 0; i < pathBits.length; i++) {
    pathCurrent += ((pathCurrent.substr(-1) != "/") ? "/" : "") + pathBits[i];
    document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;" + pathCurrent + ";";
  }
}
