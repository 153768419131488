import React, {useState} from "react";
import {
  Grid,
  TextareaAutosize,
  Box,
  Typography,
  Fab,
  Paper,
  TextField,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import {useTranslation} from "react-i18next";
import {post} from "store/api";

const ReferralLetterForm = () => {
  const {t} = useTranslation();

  // State for the fields
  const [isThinking, setIsThinking] = useState(false);
  const [letterContent, setLetterContent] = useState("");
  const [patientData, setPatientData] = useState("");
  const [formData, setFormData] = useState({
    salutation: "",
    lastName: "",
    firstName: "",
    insuranceStatus: "",
    birthDate: "",
    address: "",
    insuranceNumber: "",
    phonePrivate: "",
    phoneWork: "",
    familyDoctor: "",
    note: "",
    hyperopiaDiagnosis: "",
    anisometropiaHistory: "",
    strabismusDiagnosis: "",
    sphValue: "",
    cylValue: "",
    axisValue: "",
    eyePosition: "",
    hirschbergAngle: "",
    eyeStructuresAssessment: "",
    convergence: "",
  });

  // Function to fetch data from API and update state
  const handleCreateReferralLetter = async () => {
    try {
      setIsThinking(true);
      const response = await post("referral", {patientdata: patientData});
      setIsThinking(false);
      const data = response.data;
      setLetterContent(data.letterContent);


      setFormData({
        salutation: data.salutation || "",
        lastName: data.lastName || "",
        firstName: data.firstName || "",
        insuranceStatus: data.insuranceStatus || "",
        birthDate: data.birthDate || "",
        address: data.address || "",
        insuranceNumber: data.insuranceNumber || "",
        phonePrivate: data.phonePrivate || "",
        phoneWork: data.phoneWork || "",
        familyDoctor: data.familyDoctor || "",
        note: data.note || "",
        hyperopiaDiagnosis: data.hyperopiaDiagnosis || "",
        anisometropiaHistory: data.anisometropiaHistory || "",
        strabismusDiagnosis: data.strabismusDiagnosis || "",
        sphValue: data.sphValue || "",
        cylValue: data.cylValue || "",
        axisValue: data.axisValue || "",
        eyePosition: data.eyePosition || "",
        hirschbergAngle: data.hirschbergAngle || "",
        eyeStructuresAssessment: data.eyeStructuresAssessment || "",
        convergence: data.convergence || "",
      });
    } catch (error) {
      setIsThinking(false);
      console.error("Error fetching referral data:", error);
    }
  };

  return (
    <Box sx={{padding: "2em"}}>
      <Paper elevation={3} sx={{padding: "2em"}}>
        <Grid container spacing={2}>
          {/* Left large text area for the referral letter */}
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {t("referral.letter")}
            </Typography>
            <TextareaAutosize
              minRows={30}
              placeholder={t("referral.letterPlaceholder")}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                backgroundColor: "#f5f5f5",
              }}
              value={letterContent}
              onChange={(e) => setLetterContent(e.target.value)}
            />
          </Grid>

          {/* Right side with two smaller text areas */}
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h6" gutterBottom>
                {t("referral.patientData")}
              </Typography>
              <TextareaAutosize
                minRows={30}
                placeholder={t("referral.patientDataPlaceholder")}
                style={{width: "100%", padding: "10px", fontSize: "16px"}}
                value={patientData}
                onChange={(e) => setPatientData(e.target.value)}
              />
            </Box>
          </Grid>

          {/* Floating action button for referral letter creation */}
          <Grid item xs={12}>
            <Box textAlign="right" mt={4}>
              <Fab
                disabled={isThinking || !patientData}
                variant="extended"
                color="primary"
                aria-label="create referral letter"
                onClick={handleCreateReferralLetter}
              >
                <MailIcon sx={{mr: 1}} />
                {t("referral.createLetter")}
              </Fab>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{marginTop: "2em"}}>
          {/* Patient master data section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {t("referral.patientDataAnalysis")}
            </Typography>

            {/* Patient master data fields */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.salutation")}
                  value={formData.salutation}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.lastName")}
                  value={formData.lastName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.firstName")}
                  value={formData.firstName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.insuranceStatus")}
                  value={formData.insuranceStatus}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.birthDate")}
                  value={formData.birthDate}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.address")}
                  value={formData.address}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.insuranceNumber")}
                  value={formData.insuranceNumber}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.phonePrivate")}
                  value={formData.phonePrivate}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.phoneWork")}
                  value={formData.phoneWork}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.familyDoctor")}
                  value={formData.familyDoctor}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.note")}
                  value={formData.note}
                  fullWidth
                />
              </Grid>

              {/* Additional Fields */}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.hyperopiaDiagnosis")}
                  value={formData.hyperopiaDiagnosis}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.anisometropiaHistory")}
                  value={formData.anisometropiaHistory}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.strabismusDiagnosis")}
                  value={formData.strabismusDiagnosis}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.sphValue")}
                  value={formData.sphValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.cylValue")}
                  value={formData.cylValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.axisValue")}
                  value={formData.axisValue}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.eyePosition")}
                  value={formData.eyePosition}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.hirschbergAngle")}
                  value={formData.hirschbergAngle}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.eyeStructuresAssessment")}
                  value={formData.eyeStructuresAssessment}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label={t("referral.convergence")}
                  value={formData.convergence}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ReferralLetterForm;
