/* istanbul ignore file */
import React, {useState, useEffect, useRef} from "react";
import {CircularProgress, Box, Grid, TextareaAutosize, Typography, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {Parser} from "html-to-react";
import {OutlinedInput, InputAdornment, FormControl, InputLabel} from "@mui/material";
import {keyframes} from "@mui/material/styles";
import {postSilentFail} from "store/api";
import {
  IMedStateLimtree,
} from "../Wizard/forms/med/medicine/medicineSlice";


const IDEAutocomplete = () => {
  const spin = keyframes({
    "0%": {transform: "rotate(0deg)"},
    "100%": {transform: "rotate(360deg)"},
  });

  const [isThinking, setIsThinking] = useState<boolean>(false);
  const [isSearchingForMedicalInfo, setIsSearchingForMedicalInfo] = useState<boolean>(false);
  const [isSearchingForMedicalLimitatio, setIsSearchingForMedicalLimitatio] = useState<boolean>(false);
  const [isExtractingText, setisExtractingText] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [limitations, setLimitations] = useState<any[]>([]);
  const [medicationDetails, setMedicationDetails] = useState<{
    description: string,
    chr: string,
    cind: string,
    manufacturer: string,
    substances: string,
    interactions: string,
  }>();
  const [professionalInfo, seProfessionalInfo] = useState<string>("");
  const [currentSuggestion, setCurrentSuggestion] = useState<string>("");
  const [medication, setMedication] = useState<string>("");
  const [diagnosis, setDiagnosis] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [inCombinationMed, setInCombinationMed] = useState<string>("");
  const [medicalInfo, setMedicalInfo] = useState<string[]>([]);
  const debounceTimeout = useRef<null | any>(null);
  const textareaRef = useRef(null);
  const {t, i18n} = useTranslation();
  const htmlToReactParser = Parser();
  const currentLang=i18n.language.toUpperCase();


  const fetchSuggestions = async (query) => {
    if (query.length >= 1) {
      try {
        // Clear the previous timeout if the user is still typing
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to fetch suggestions after 1 second of no typing
        debounceTimeout.current = setTimeout(() => {
          setIsThinking(true);
          postSilentFail("ailetter/getcompletion", {prompt: query}).then((response: any) => {
            console.debug("ai response", response);
            setIsThinking(false);
            if (response?.suggestion?.length > 0) {
              setSuggestion(response.suggestion);
              /* setMedication(response.medication);
              setDiagnosis(response.diagnosis);
              setGender(response.gender);
              setAge(response.age);
              setName(response.name);
              setInCombinationMed(response.incombination);*/
            } else {
              clearSuggestion();
            }
          });
        }, 1000); // 1 second debounce
      } catch (error) {
        setIsThinking(false);
        console.error("Error fetching suggestions:", error);
        clearSuggestion();
      }
    } else {
      clearSuggestion();
    }
  };

  // Clear the suggestion
  const clearSuggestion = () => {
    setCurrentSuggestion("");
    setSuggestion("");
  };

  // Handle input change and call debounced fetchSuggestions
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    fetchSuggestions(value); // Debounced API call
  };

  // Handle keydown event for the "Tab" key
  const handleKeyDown = (e) => {
    if (e.key === "Tab" && suggestion) {
      e.preventDefault(); // Prevent default Tab behavior (switching focus)

      // Append the suggestion to the current input value
      setInputValue((prevValue) => prevValue + suggestion);

      clearSuggestion(); // Clear the suggestion once accepted
    }
  };


  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      console.debug("ai useEffect", medication);
      if (!medication || !diagnosis || !age || !gender) {
        return;
      }
      setIsSearchingForMedicalInfo(true);
      postSilentFail("ailetter/getmedicalinfo", {
        prompt: {
          medication: medication,
          diagnosis: diagnosis,
          age: age,
          inCombinationMed: inCombinationMed,
          gender: gender,
        },
      })
          .then((response: any) => {
            setIsSearchingForMedicalInfo(false);
            console.debug("ai response medical info", response);
            if (response?.content && response?.content.length > 0) {
              response.content = response?.content.replace(/'/g, "");
              response.content = response?.content.replace(/"/g, "");
              response.content = response?.content.replace(/```/g, "");
              setMedicalInfo(() => [response?.content]);
            }
          })
          .catch((error) => {
            setIsSearchingForMedicalInfo(false);
            console.error("Error fetching medical info:", error);
            setMedicalInfo([]);
          });
    }, 1000); // Set the debounce time to 500ms (adjustable based on need)

    // Cleanup function to clear timeout if dependencies change within the debounce time
    return () => clearTimeout(debounceTimeout);
  }, [medication, diagnosis, age, gender, inCombinationMed]);


  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      console.debug("ai useEffect", medication);
      if (!medication) {
        return;
      }
      setIsSearchingForMedicalLimitatio(true);
      postSilentFail("ailetter/search", {
        search: medication,
      })
          .then((response: any) => {
            setIsSearchingForMedicalLimitatio(false);
            console.debug("ai response medical info", response);
            if (response?.content && response?.content.length > 0) {
              setMedicalInfo(() => [response?.content]);
            }
            setLimitations(response?.limtree);
            seProfessionalInfo(response?.professionalPatientInformationInHtmlFormat);
            const medicalDetails ={
              chr: response?.CHR,
              cind: response?.CIND,
              manufacturer: response?.manufacturer,
              substances: response?.substances,
              interactions: response?.interactions,
              description: response?.description,
            };

            setMedicationDetails(medicalDetails);

            console.debug("ai response medical professionalInfo ", professionalInfo);
            console.debug("ai response medical limitations ", limitations);
          })
          .catch((error) => {
            setIsSearchingForMedicalLimitatio(false);
            setLimitations([]);
            seProfessionalInfo("");
          });
    }, 1000); // Set the debounce time to 500ms (adjustable based on need)

    // Cleanup function to clear timeout if dependencies change within the debounce time
    return () => clearTimeout(debounceTimeout);
  }, [medication]);


  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (!inputValue) {
        return;
      }
      setisExtractingText(true);
      postSilentFail("ailetter/getInformationByText", {
        prompt: inputValue,
      })
          .then((response: any) => {
            setisExtractingText(false);
            setMedication(response.medication);
            setDiagnosis(response.diagnosis);
            setGender(response.gender);
            setAge(response.age);
            setName(response.name);
            setInCombinationMed(response.incombination);
          })
          .catch((error) => {
            setisExtractingText(false);
          });
    }, 1000);
    return () => clearTimeout(debounceTimeout);
  }, [inputValue]);


  return (
    <Grid container xs={12}>
      <Paper sx={{padding: "1em", width: "100%", minWidth: "100%", minHeight: "100%", marginBottom: "1em"}}>
        <Grid container spacing={1} sx={{maxWidth: "1200px", margin: "0 auto"}}>
          <Grid
            container
            spacing={1}
            item
            xs={12}
            sx={{
              padding: "0.5em",
              maxWidth: "1200px",
              margin: "0 auto",
              display: "flex",
              alignItems: "center", // Align vertically in the center
            }}
          >
            <Typography variant="h4" sx={{marginRight: "1em"}}>{t("ai.title")}</Typography>
          </Grid>
          <Grid spacing={1} item xs={12} sx={{padding: "0.5em", maxWidth: "1200px", margin: "0 auto"}}>
            <Typography >{t("ai.text")}</Typography>
          </Grid>

          <Grid item xs={12} sx={{padding: "0.5em", maxWidth: "1200px", margin: "0 auto"}}>
            {
              (isThinking || isSearchingForMedicalInfo || isSearchingForMedicalLimitatio || isExtractingText) ? (
                <div style={{display: "flex", alignItems: "center"}}>
                  <span>{t("ai.analyze")}...</span>
                  <CircularProgress style={{marginLeft: "10px"}} />
                </div>
              ) : (
                <div style={{height: "40px"}}>
                </div>
              )
            }
          </Grid>

          <Grid item xs={12} md={8}>

            <Box sx={{position: "relative", fontSize: "16px"}}>
              <TextareaAutosize
                ref={textareaRef}
                id="autocomplete-input"
                value={inputValue}
                minRows={30}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={t("ai.placeholder")}
                style={{width: "100%", padding: "10px", fontSize: "16px", lineHeight: "1.5"}}

              />

              {/* Status bar at the bottom */}
              <Box
                sx={{
                  textAlign: "center",
                  width: "100%",
                  position: "relative", // Ensure positioning context for the spinner
                }}
              >
                <FormControl variant="outlined" fullWidth margin="dense">
                  <InputLabel>{t("ai.suggestions")}</InputLabel>
                  <OutlinedInput
                    label={t("ai.suggestions")}
                    readOnly
                    value={suggestion}
                    endAdornment={
                      <InputAdornment position="end">
                        {isThinking && <CircularProgress size={24} />}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          </Grid>

          {/* Infobar on the right side */}
          <Grid item xs={12} md={4} spacing={0}>

            <Box
              sx={{
                height: "100%",
                overflowY: "auto", // Enable vertical scrolling
                maxHeight: "calc(100vh - 60px)", // Adjust max height
              }}
            >
              {(age || gender || diagnosis || medication || inCombinationMed || name) &&
              <Grid item xs={12}>
                <Accordion expanded={true}
                  disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
                  <AccordionSummary>
                    <Typography variant="h5">{t("ai.letter.ocr")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isExtractingText ? (
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100px">
                      <CircularProgress />
                    </Box>
                    ) : (

                    <Box>
                      <Typography>{t("ai.age")}:&nbsp;{age}</Typography>
                      <Typography>{t("ai.gender")}:&nbsp;{gender}</Typography>
                      <Typography>{t("ai.diagnosis")}:&nbsp;{diagnosis}</Typography>
                      <Typography>{t("ai.medication")}:&nbsp;{medication}</Typography>
                      <Typography>{t("ai.inCombinationMed")}:&nbsp;{inCombinationMed}</Typography>
                      <Typography>{t("ai.name")}:&nbsp;{name}</Typography>
                    </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              }
              {
                !isSearchingForMedicalLimitatio && medicationDetails &&
            <Grid item xs={12}>
              <Accordion
                disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}>
                  <Typography variant="h5">{t("medication.details")}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                  <Typography>{medicationDetails?.description}</Typography>
                  <Typography>{medicationDetails?.chr}</Typography>
                  <Typography>{medicationDetails?.cind}</Typography>
                  <Typography>{medicationDetails?.manufacturer}</Typography>
                  <Typography>{medicationDetails?.description}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
              }


              {medicalInfo && medicalInfo instanceof Array && medicalInfo.length > 0 &&
          <Grid item xs={12}>
            <Accordion expanded={medicalInfo?.length > 0}
              disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
              <AccordionSummary>
                <Typography variant="h5">{t("ai.hints")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {isSearchingForMedicalInfo ? (
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100px">
                      <CircularProgress />
                    </Box>
                    ) : (
                    <Box >
                      {medicalInfo && medicalInfo instanceof Array && medicalInfo.length > 0 ? (
                        medicalInfo.map((item, index) => (
                          <Typography key={index}>
                            {item}
                          </Typography>
                        ))
                        ) : (
                        <Typography>No medical info available</Typography>
                        )}
                    </Box>
                    )}
              </AccordionDetails>
            </Accordion>
          </Grid>
              }


              {
                !isSearchingForMedicalLimitatio && professionalInfo&&
            <Grid item xs={12}>
              <Accordion
                disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
                  aria-controls="PatientInformation" id="PatientInformation-header">
                  <Typography variant="h5">{t("limitation.details")}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                  {(
                    typeof htmlToReactParser?.parse === "function"&&
                    htmlToReactParser?.parse(professionalInfo))||
                    ""}
                </AccordionDetails>
              </Accordion>
            </Grid>
              }
              {!isSearchingForMedicalLimitatio && limitations&&limitations?.length > 0 &&
            limitations[0].LIMITATIONSGERMAN?.length > 0 && <Grid item xs={12}>
                <Accordion disableGutters
                  sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
                    aria-controls="limmitations-content" id="limmitations-header">
                    <Typography variant="h5">{t("fach.limitation")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{minWidth: "100%"}} >
                    <Grid container direction={"column"} spacing={1}>
                      {[...limitations || []]
                          .sort((a: IMedStateLimtree, b: IMedStateLimtree) => {
                            if (a.INDCDSTRING < b.INDCDSTRING) return -1;
                            if (a.INDCDSTRING > b.INDCDSTRING) return 1;
                            return 0;
                          })

                          ?.map((limitation:IMedStateLimtree, index:number) => {
                            return <Grid key={`sameMedicine_${index}`} item >
                              {currentLang !== "FR" &&
                    <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                      <strong>
                        {limitation?.INDCDSTRING}&nbsp;</strong>
                      {(typeof htmlToReactParser?.parse === "function"&&htmlToReactParser?.parse(limitation?.LIMITATIONSGERMAN)) || ""}
                    </Typography>
                              }
                              {currentLang === "FR" &&
                   <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                     <strong>
                       {limitation?.INDCDSTRING}&nbsp;</strong>
                     {(typeof htmlToReactParser?.parse === "function"&&htmlToReactParser?.parse(limitation?.LIMITATIONSFRENCH)) || ""}
                   </Typography>
                              }
                            </Grid>;
                          })}
                    </Grid>
                  </AccordionDetails>
                </Accordion></Grid>
              }


            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default IDEAutocomplete;
