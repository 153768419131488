import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

// Array to keep track of field names in order
const fieldNames = [
  "firstSevereTreatmentResistantMajorDepressiveEpisode",
  "firstAlternativeIndication",
  "secondRequestingPhysicianIsPsychiatristOrPsychotherapist",
  "secondRequestingPhysicianWorksInBAGApprovedInstitution",
  "secondBagApprovedInstitution",
  "thirdBeforeTherapyCommencement",
  "thirdProgressReviewAfter4Weeks",
  "thirdProgressReviewAfter4MonthsOfTherapy",
  "thirdProgressReviewAfter7MonthsofTherapy",
  "thirdTherapyDurationOf10MonthsOrMore",
  "fourthPatientIsAged18to74YearsInclusive",
  "fourthPatientHasPreviouslyReceived2DifferentAntidepressants",
  "fourthAntidepressiva",
  "fourthAntidepressivaEffect",
  "fourthAntidepressivaEffect2",
  "fourthTherapieStart",
  "fourthTherapieEnd",
  "fourthAntidepressiva2",
  "fourthTherapieStart2",
  "fourthTherapieEnd2",
  "fifthPatientHasPreviouslyReceived1Augmentation",
  "fifthAugmentation",
  "sixthCGISBeforeTreatmentWithSPRAVATO5",
  "sixthCGISBeforeTreatmentWithSPRAVATO",
  "seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable",
  "seventhReasons",
  "seventhComments",
  "seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant",
  "eightCGISBeforeTreatmentWithSPRAVATO5",
  "eightCGISBeforeTreatmentWithSPRAVATO",
  "ninthCGICheckBox",
  "ninthCGIS1",
  "ninthCGIS2",
  "tenthCGICheckBox",
  "tenthCGIS1",
  "eleventhCGICheckBox",
  "eleventhCGIS1",
  "preTreatment",
  "patientCondition",
  "prognosis",
  "reasonsAlternativeTherapiesFailed",
];

export interface SpravatoState {
  formData: {
    // first option
    firstSevereTreatmentResistantMajorDepressiveEpisode: boolean,
    firstAlternativeIndication: boolean,

    // second option
    secondRequestingPhysicianIsPsychiatristOrPsychotherapist: boolean,
    secondRequestingPhysicianWorksInBAGApprovedInstitution: boolean,
    secondBagApprovedInstitution: string

    // third option
    thirdBeforeTherapyCommencement: boolean,
    thirdProgressReviewAfter4Weeks: boolean,
    thirdProgressReviewAfter4MonthsOfTherapy: boolean,
    thirdProgressReviewAfter7MonthsofTherapy: boolean,
    thirdTherapyDurationOf10MonthsOrMore: boolean

    // fourth option
    fourthPatientIsAged18to74YearsInclusive: boolean,
    fourthPatientHasPreviouslyReceived2DifferentAntidepressants: boolean,
    fourthAntidepressiva: string,
    fourthTherapieStart: Date | null
    fourthTherapieEnd: Date | null
    fourthAntidepressivaEffect: string,
    fourthAntidepressiva2: string,
    fourthTherapieStart2: Date | null
    fourthTherapieEnd2: Date | null
    fourthAntidepressivaEffect2: string,

    // fifth option
    fifthPatientHasPreviouslyReceived1Augmentation : boolean,
    fifthAugmentation: string,

    // sixth option
    sixthCGISBeforeTreatmentWithSPRAVATO5 : boolean
    sixthCGISBeforeTreatmentWithSPRAVATO: number,

    // seventh option
    seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable: boolean,
    seventhReasons: string,
    seventhComments: string,
    seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant: string,

    // IF thirdProgressReviewAfter4MonthsOfTherapy === true
    eightCGISBeforeTreatmentWithSPRAVATO5: boolean,
    eightCGISBeforeTreatmentWithSPRAVATO: number,


    // IF thirdProgressReviewAfter4Weeks === true
    ninthCGICheckBox: boolean,
    ninthCGIS1: number,
    ninthCGIS2: number,


    // Verlaufskontrolle nach 7 Monaten Therapie
    tenthCGICheckBox: boolean,
    tenthCGIS1: number,

    // Die Therapie dauert bereits 10 oder mehr Monate an
    eleventhCGICheckBox: boolean,
    eleventhCGIS1: number,

    // default stuff
    variant: string
    default: boolean,
    preTreatment: string
    patientCondition: string,
    prognosis: string,
    reasonsAlternativeTherapiesFailed: string,


  }
}

const initialState:SpravatoState = {
  formData: {
    // first option
    firstSevereTreatmentResistantMajorDepressiveEpisode: false,
    firstAlternativeIndication: false,

    // second option
    secondRequestingPhysicianIsPsychiatristOrPsychotherapist: false,
    secondRequestingPhysicianWorksInBAGApprovedInstitution: false,
    secondBagApprovedInstitution: "",

    // third option
    thirdBeforeTherapyCommencement: false,
    thirdProgressReviewAfter4Weeks: false,
    thirdProgressReviewAfter4MonthsOfTherapy: false,
    thirdProgressReviewAfter7MonthsofTherapy: false,
    thirdTherapyDurationOf10MonthsOrMore: false,

    // fourth option
    fourthPatientIsAged18to74YearsInclusive: false,
    fourthPatientHasPreviouslyReceived2DifferentAntidepressants: false,
    fourthAntidepressiva: "",
    fourthTherapieStart: null,
    fourthTherapieEnd: null,
    fourthAntidepressivaEffect: "",

    fourthAntidepressiva2: "",
    fourthTherapieStart2: null,
    fourthTherapieEnd2: null,
    fourthAntidepressivaEffect2: "",


    // fifth option
    fifthPatientHasPreviouslyReceived1Augmentation: false,
    fifthAugmentation: "",

    // sixth option
    sixthCGISBeforeTreatmentWithSPRAVATO5: false,
    sixthCGISBeforeTreatmentWithSPRAVATO: 0,

    // seventh option
    seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable: false,
    seventhReasons: "",
    seventhComments: "",
    seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant: "",

    variant: "",
    default: false,
    preTreatment: "",
    patientCondition: "",
    prognosis: "",
    reasonsAlternativeTherapiesFailed: "",

    // IF thirdProgressReviewAfter4MonthsOfTherapy === true
    eightCGISBeforeTreatmentWithSPRAVATO5: false,
    eightCGISBeforeTreatmentWithSPRAVATO: 0,

    // IF thirdProgressReviewAfter4Weeks === true
    ninthCGICheckBox: false,
    ninthCGIS1: 0,
    ninthCGIS2: 0,

    // Verlaufskontrolle nach 7 Monaten Therapie
    tenthCGICheckBox: false,
    tenthCGIS1: 0,

    // Die Therapie dauert bereits 10 oder mehr Monate an
    eleventhCGICheckBox: false,
    eleventhCGIS1: 0,
  },
};

const SpravatoFormSlice = createSlice({
  name: "Spravato",
  initialState,
  reducers: {
    onClearSpravatoState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedSpravatoEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state);
      if (payload.formData) {
        state.formData.preTreatment =
          payload.formData?.preTreatment;
        state.formData.patientCondition =
          payload.formData?.patientCondition;
        state.formData.reasonsAlternativeTherapiesFailed =
          payload.formData?.reasonsAlternativeTherapiesFailed;
        state.formData.prognosis =
          payload.formData?.prognosis;
      }
    },
    onChangeSpravatoValue: (state, {payload}: PayloadAction<any>) => {
      console.debug("onChangeSpravatoValue", payload);
      if (payload.preTreatment || payload.preTreatment === "") state.formData.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.prognosis = payload.prognosis;
      updatestate(payload, state);
    },
  },
});


export const {
  onReceiveSpecialMedSpravatoEditData,
  onClearSpravatoState,
  onChangeSpravatoValue,
} = SpravatoFormSlice.actions;

export default SpravatoFormSlice.reducer;
export const selectSpravatoValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.spravatoForm?.formData;


function updatestate(payload: any, state) {
  const data = payload.formData;
  console.debug("data", JSON.stringify(data));
  if (!data) return;

  /* <migration> */
  // if is not numeric, call getCGIValueByLabel
  data.sixthCGISBeforeTreatmentWithSPRAVATO = getCGIValueByLabel(data.sixthCGISBeforeTreatmentWithSPRAVATO);
  data.eightCGISBeforeTreatmentWithSPRAVATO = getCGIValueByLabel(data.eightCGISBeforeTreatmentWithSPRAVATO);
  data.ninthCGIS1 = getCGIValueByLabel(data.ninthCGIS1);
  data.ninthCGIS2 = getCGIValueByLabel(data.ninthCGIS2);
  data.tenthCGIS1 = getCGIValueByLabel(data.tenthCGIS1);
  data.eleventhCGIS1 = getCGIValueByLabel(data.eleventhCGIS1);
  /* </migration> */


  // first option
  if (data.firstSevereTreatmentResistantMajorDepressiveEpisode === true) {
    state.formData.firstSevereTreatmentResistantMajorDepressiveEpisode = true;
    state.formData.firstAlternativeIndication = false;
  } else if (data.firstAlternativeIndication === true) {
    state.formData.firstSevereTreatmentResistantMajorDepressiveEpisode = false;
    state.formData.firstAlternativeIndication = true;
    resetBelow(fieldNames, "firstAlternativeIndication", data, state);
  }

  // second option
  if (data.secondRequestingPhysicianIsPsychiatristOrPsychotherapist !== undefined) {
    state.formData.secondRequestingPhysicianIsPsychiatristOrPsychotherapist =
    data.secondRequestingPhysicianIsPsychiatristOrPsychotherapist;
  }
  if (data.secondRequestingPhysicianWorksInBAGApprovedInstitution !== undefined) {
    state.formData.secondRequestingPhysicianWorksInBAGApprovedInstitution =
    data.secondRequestingPhysicianWorksInBAGApprovedInstitution;
  }
  if (data.secondBagApprovedInstitution || data.secondBagApprovedInstitution === "") {
    state.formData.secondBagApprovedInstitution = data.secondBagApprovedInstitution;
    resetBelow(fieldNames, "secondBagApprovedInstitution", data, state);
  }
  if (
    data.secondRequestingPhysicianWorksInBAGApprovedInstitution === false ||
    data.secondRequestingPhysicianIsPsychiatristOrPsychotherapist === false
  ) {
    resetBelow(fieldNames, "secondBagApprovedInstitution", data, state);
  }

  // third option
  if (data.thirdBeforeTherapyCommencement === true) {
    state.formData.thirdBeforeTherapyCommencement = true;
    state.formData.thirdProgressReviewAfter4Weeks = false;
    state.formData.thirdProgressReviewAfter4MonthsOfTherapy = false;
    state.formData.thirdProgressReviewAfter7MonthsofTherapy = false;
    state.formData.thirdTherapyDurationOf10MonthsOrMore = false;
    // resetBelow(fieldNames, "thirdTherapyDurationOf10MonthsOrMore", data, state);
  } else if (data.thirdProgressReviewAfter4Weeks === true) {
    state.formData.thirdBeforeTherapyCommencement = false;
    state.formData.thirdProgressReviewAfter4Weeks = true;
    state.formData.thirdProgressReviewAfter4MonthsOfTherapy = false;
    state.formData.thirdProgressReviewAfter7MonthsofTherapy = false;
    state.formData.thirdTherapyDurationOf10MonthsOrMore = false;
    // resetBelow(fieldNames, "thirdTherapyDurationOf10MonthsOrMore", data, state);
  } else if (data.thirdProgressReviewAfter4MonthsOfTherapy === true) {
    state.formData.thirdBeforeTherapyCommencement = false;
    state.formData.thirdProgressReviewAfter4Weeks = false;
    state.formData.thirdProgressReviewAfter4MonthsOfTherapy = true;
    state.formData.thirdProgressReviewAfter7MonthsofTherapy = false;
    state.formData.thirdTherapyDurationOf10MonthsOrMore = false;
    // resetBelow(fieldNames, "thirdTherapyDurationOf10MonthsOrMore", data, state);
  } else if (data.thirdProgressReviewAfter7MonthsofTherapy === true) {
    state.formData.thirdBeforeTherapyCommencement = false;
    state.formData.thirdProgressReviewAfter4Weeks = false;
    state.formData.thirdProgressReviewAfter4MonthsOfTherapy = false;
    state.formData.thirdProgressReviewAfter7MonthsofTherapy = true;
    state.formData.thirdTherapyDurationOf10MonthsOrMore = false;
    // resetBelow(fieldNames, "thirdTherapyDurationOf10MonthsOrMore", data, state);
  } else if (data.thirdTherapyDurationOf10MonthsOrMore === true) {
    state.formData.thirdBeforeTherapyCommencement = false;
    state.formData.thirdProgressReviewAfter4Weeks = false;
    state.formData.thirdProgressReviewAfter4MonthsOfTherapy = false;
    state.formData.thirdProgressReviewAfter7MonthsofTherapy = false;
    state.formData.thirdTherapyDurationOf10MonthsOrMore = true;
    // resetBelow(fieldNames, "thirdTherapyDurationOf10MonthsOrMore", data, state);
  }

  // fourth option
  if (data.fourthPatientIsAged18to74YearsInclusive != undefined) {
    state.formData.fourthPatientIsAged18to74YearsInclusive =
    data.fourthPatientIsAged18to74YearsInclusive;
  }

  if (data.fourthPatientHasPreviouslyReceived2DifferentAntidepressants != undefined) {
    state.formData.fourthPatientHasPreviouslyReceived2DifferentAntidepressants =
    data.fourthPatientHasPreviouslyReceived2DifferentAntidepressants;
  }

  if (data.fourthAntidepressiva || data.fourthAntidepressiva === "") {
    state.formData.fourthAntidepressiva = data.fourthAntidepressiva;
  }
  if (data.fourthAntidepressivaEffect || data.fourthAntidepressivaEffect === "") {
    state.formData.fourthAntidepressivaEffect = data.fourthAntidepressivaEffect;
  }
  if (data.fourthAntidepressivaEffect2 || data.fourthAntidepressivaEffect2 === "") {
    state.formData.fourthAntidepressivaEffect2 = data.fourthAntidepressivaEffect2;
  }

  if (data.fourthTherapieStart || data.fourthTherapieStart === null) {
    state.formData.fourthTherapieStart = data.fourthTherapieStart;
  }
  if (data.fourthTherapieEnd || data.fourthTherapieEnd === null) {
    state.formData.fourthTherapieEnd = data.fourthTherapieEnd;
  }
  if (data.fourthAntidepressiva2 || data.fourthAntidepressiva2 === "") {
    state.formData.fourthAntidepressiva2 = data.fourthAntidepressiva2;
  }

  if (data.fourthTherapieStart2 || data.fourthTherapieStart2 === null) {
    state.formData.fourthTherapieStart2 = data.fourthTherapieStart2;
  }
  if (data.fourthTherapieEnd2 || data.fourthTherapieEnd2 === null) {
    state.formData.fourthTherapieEnd2 = data.fourthTherapieEnd2;
  }

  // fifth option
  if (data.fifthPatientHasPreviouslyReceived1Augmentation === true) {
    state.formData.fifthPatientHasPreviouslyReceived1Augmentation = true;
  } else if (data.fifthPatientHasPreviouslyReceived1Augmentation === false) {
    state.formData.fifthPatientHasPreviouslyReceived1Augmentation = false;
  }
  if (data.fifthAugmentation || data.fifthAugmentation === "") {
    state.formData.fifthAugmentation = data.fifthAugmentation;
  }

  // sixth option
  if (data.sixthCGISBeforeTreatmentWithSPRAVATO5 === true) {
    state.formData.sixthCGISBeforeTreatmentWithSPRAVATO5 = true;
  } else if (data.sixthCGISBeforeTreatmentWithSPRAVATO5 === false) {
    state.formData.sixthCGISBeforeTreatmentWithSPRAVATO5 = false;
  }
  if (data.sixthCGISBeforeTreatmentWithSPRAVATO || data.sixthCGISBeforeTreatmentWithSPRAVATO === 0) {
    state.formData.sixthCGISBeforeTreatmentWithSPRAVATO = data.sixthCGISBeforeTreatmentWithSPRAVATO;
  }

  // seventh option
  if (data.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable === true) {
    state.formData.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable = true;
  } else if (data.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable === false) {
    state.formData.seventhInterventionalTherapyNotIndicatedOrDeclinedByPatientOrNoAccessAvailable = false;
  }

  if (data.seventhReasons || data.seventhReasons === "") {
    state.formData.seventhReasons = data.seventhReasons;
  }
  if (data.seventhComments || data.seventhComments === "") {
    state.formData.seventhComments = data.seventhComments;
  }
  if (data.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant || data.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant === "") {
    state.formData.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant =
    data.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant;
  }


  // eight option
  // Da Sie die Option "Verlaufskontrolle nach 4 Monaten Therapie" ausgewählt haben:
  if (data.eightCGISBeforeTreatmentWithSPRAVATO5 === true || data.eightCGISBeforeTreatmentWithSPRAVATO5 === false) {
    state.formData.eightCGISBeforeTreatmentWithSPRAVATO5 = data.eightCGISBeforeTreatmentWithSPRAVATO5;
  }
  if (data.eightCGISBeforeTreatmentWithSPRAVATO || data.eightCGISBeforeTreatmentWithSPRAVATO === 0) {
    state.formData.eightCGISBeforeTreatmentWithSPRAVATO = data.eightCGISBeforeTreatmentWithSPRAVATO;
  }

  // ninth option
  if (data.ninthCGICheckBox === true || data.ninthCGICheckBox === false) {
    state.formData.ninthCGICheckBox = data.ninthCGICheckBox;
  }
  if (data.ninthCGIS1 || data.ninthCGIS1 === 0) {
    state.formData.ninthCGIS1 = data.ninthCGIS1;
  }
  if (data.ninthCGIS2 || data.ninthCGIS2 === 0) {
    state.formData.ninthCGIS2 = data.ninthCGIS2;
  }

  // Verlaufskontrolle nach 7 Monaten Therapie
  if (data.tenthCGICheckBox === true || data.tenthCGICheckBox === false) {
    state.formData.tenthCGICheckBox = data.tenthCGICheckBox;
  }
  if (data.tenthCGIS1 || data.tenthCGIS1 === 0) {
    state.formData.tenthCGIS1 = data.tenthCGIS1;
  }

  // Die Therapie dauert bereits 10 oder mehr Monate an
  if (data.eleventhCGICheckBox === true || data.eleventhCGICheckBox === false) {
    state.formData.eleventhCGICheckBox = data.eleventhCGICheckBox;
  }
  if (data.eleventhCGIS1 || data.eleventhCGIS1 === 0) {
    state.formData.eleventhCGIS1 = data.eleventhCGIS1;
  }

  /*
    Delete fields not shown
  */

  if (!state.formData.thirdProgressReviewAfter4MonthsOfTherapy) {
    // delete fields not shown
    state.formData.eightCGISBeforeTreatmentWithSPRAVATO = 0;
    state.formData.eightCGISBeforeTreatmentWithSPRAVATO5 = false;
  }

  if (!state.formData.thirdProgressReviewAfter4Weeks) {
    // delete fields not shown
    state.formData.ninthCGICheckBox = false;
    state.formData.ninthCGIS1 = 0;
    state.formData.ninthCGIS2 = 0;
  }

  if (!state.formData.thirdProgressReviewAfter7MonthsofTherapy) {
    // delete fields not shown
    state.formData.tenthCGICheckBox = false;
    state.formData.tenthCGIS1 = 0;
  }

  if (!state.formData.thirdTherapyDurationOf10MonthsOrMore) {
    // delete fields not shown
    state.formData.eleventhCGICheckBox = false;
    state.formData.eleventhCGIS1 = 0;
  }

  if (
    !(state.formData.secondBagApprovedInstitution?.length > 0 &&
      state.formData.secondRequestingPhysicianWorksInBAGApprovedInstitution &&
      state.formData.secondRequestingPhysicianIsPsychiatristOrPsychotherapist)
  ) {
    // delete fields not shown
    state.formData.seventhComments = "";
    state.seventhSpravatoIsAdministeredInCombinationWithTheFollowingOralAntidepressant = "";
  }
}

function resetBelow(fieldNames, changedField, data, state) {
  let reset = false;

  fieldNames.forEach((field) => {
    if (reset) {
      // Reset boolean fields to false
      if (typeof state.formData[field] === "boolean") {
        state.formData[field] = false;
      }
      // Reset string fields to empty string
      if (typeof state.formData[field] === "string") {
        state.formData[field] = "";
      }
      // Reset fields that can be null
      if (state.formData[field] === null || typeof state.formData[field] === "undefined") {
        state.formData[field] = null;
      }
    }

    // If this is the changed field, start resetting subsequent fields
    if (field === changedField) {
      reset = true;

      // Apply the change for the current field
      state.formData[field] = data[field];
    }
  });
}


/* for migration of old values */
const cgiOptions = [
  {
    "value": 0,
    "label": "Nicht beurteilt (0)",
  },
  {
    "value": 1,
    "label": "Normal/nicht erkrankt (1)",
  },
  {
    "value": 2,
    "label": "Grenzwertig erkrankt (2)",
  },
  {
    "value": 3,
    "label": "Leicht erkrankt (3)",
  },
  {
    "value": 4,
    "label": "Moderat erkrankt (4)",
  },
  {
    "value": 5,
    "label": "Schwer erkrankt (5)",
  },
  {
    "value": 6,
    "label": "Sehr schwer erkrankt (6)",
  },
  {
    "value": 7,
    "label": "Maximal schwer erkrankt (7)",
  },
  {
    "value": 0,
    "label": "Non évalué (0)",
  },
  {
    "value": 1,
    "label": "Normal/non malade (1)",
  },
  {
    "value": 2,
    "label": "A la limite (2)",
  },
  {
    "value": 3,
    "label": "Légèrement malade (3)",
  },
  {
    "value": 4,
    "label": "Modérément malade (4)",
  },
  {
    "value": 5,
    "label": "Sévèrement malade (5)",
  },
  {
    "value": 6,
    "label": "Très gravement malade (6)",
  },
  {
    "value": 7,
    "label": "Maximum sévèrement malade (7)",
  },
  {
    "value": 0,
    "label": "Non valutato (0)",
  },
  {
    "value": 1,
    "label": "Normale/non malato (1)",
  },
  {
    "value": 2,
    "label": "Malati borderline (2)",
  },
  {
    "value": 3,
    "label": "Leggermente malato (3)",
  },
  {
    "value": 4,
    "label": "Moderatamente malato (4)",
  },
  {
    "value": 5,
    "label": "Gravemente malato (5)",
  },
  {
    "value": 6,
    "label": "Malati molto gravi (6)",
  },
  {
    "value": 7,
    "label": "Massima gravità della malattia (7)",
  },
];


function getCGIValueByLabel(label) {
  if (typeof label === "number") return label;
  const option = cgiOptions.find((option) => option.label === label);
  return option ? option.value : label;
}


