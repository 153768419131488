import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";

import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";

import {
  onChangeTecvayliValue,
  selectTecvayliValues,
  onAddMedication,
  onRemoveMedication,
} from "./TecvayliSlice";

import DrugPlanForm from "./DrugPlanForm";

const TecvayliForm = (props) => {
  const {t, i18n}=useTranslation();
  const dispatch = UseAppDispatch();
  const TecvayliValues = UseAppSelector(selectTecvayliValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const currentLang=i18n.language.toUpperCase();


  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);

  useEffect(() => {
    if (TecvayliValues.isMonotherapy) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: true,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeMedicineValue({field: "kn_id", value: "21518.01"}));
    } else {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
    }
  }, [
    TecvayliValues.isMonotherapy,
  ]);


  const handleAddMedication = () => {
    dispatch(onAddMedication({name: "", start: "", stop: "", comment: ""})); // Add an empty medication item
  };

  const handleRemoveMedication = (index) => {
    dispatch(onRemoveMedication(index));
  };

  const handleMedicationChange = (index, field, value) => {
    const updatedMedications = TecvayliValues.medications.map((medication, idx) =>
      idx === index ? {...medication, [field]: value} : medication,
    );
    dispatch(onChangeTecvayliValue({formData: {medications: updatedMedications}}));
  };


  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="tecvayli-radio-buttons-group-form-type"
              name="tecvayli-radio-buttons-group-form-type"
              onChange={(event, value) => {
                if (value === "isOtherIndication") {
                  dispatch(onChangeTecvayliValue({formData: {isMonotherapy: false}}));
                  dispatch(onChangeTecvayliValue({formData: {isOtherIndication: true}}));
                } else {
                  dispatch(onChangeTecvayliValue({formData: {isMonotherapy: true}}));
                  dispatch(onChangeTecvayliValue({formData: {isOtherIndication: false}}));
                }
              }}>
              <FormControlLabel
                value="isMonotherapy"
                control={<Radio checked={TecvayliValues.isMonotherapy}
                  data-cy="isMonotherapy" />}
                label={t("tecvayli.isMonotherapy")}
              />
              <FormControlLabel
                value="isOtherIndication"
                control={<Radio checked={TecvayliValues.isOtherIndication}
                  data-cy="isOtherIndication" />}
                label={t("tecvayli.isOtherIndication")}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{m: 1}}/>
        </Grid >
        <Grid item xs={12} >
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
            hidden={!TecvayliValues.isMonotherapy}>
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("tecvayli.warning.limitatio")}</Typography>
          </Stack>
        </Grid>

        {
          // Medication Block Array
        }

        <Grid item xs={12} hidden={!TecvayliValues.isMonotherapy}>
          {TecvayliValues.medications.map((medication, index) => (
            <Grid key={index} container spacing={2} >
              <Grid xs={10} item>
                <Typography>{t("tecvayli.drugplan.therapy")}: {index+1}</Typography>
                <DrugPlanForm
                  data-cy="medication-name"
                  label={t("tecvayli.medicationName")}
                  value={medication.name}
                  onChange={(value) => handleMedicationChange(index, "name", value)}
                />
              </Grid>
              <Grid xs={2} item display="flex" alignItems="center" justifyContent="center">
                <IconButton
                  data-cy="removeMedication"
                  onClick={() => handleRemoveMedication(index)} color="secondary">
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  inputFormat="MM/yyyy"
                  label={t("tecvayli.start")}
                  views={["year", "month"]}
                  value={medication.start}
                  onChange={(newValue) => handleMedicationChange(index, "start", newValue)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth data-cy="start-date" variant="outlined" InputLabelProps={{shrink: true}} />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  inputFormat="MM/yyyy"
                  label={t("tecvayli.stop")}
                  views={["year", "month"]}
                  value={medication.stop}
                  onChange={(newValue) => handleMedicationChange(index, "stop", newValue)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth data-cy="stop-date" variant="outlined" InputLabelProps={{shrink: true}} />
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  label={t("tecvayli.comment")}
                  variant="outlined"
                  value={medication.comment}
                  fullWidth
                  data-cy="medication-comment"
                  onChange={(event) => handleMedicationChange(index, "comment", event.target.value)}
                />
              </Grid>
              <Box sx={{m: 1}}/>
            </Grid>

          ))}
          <Box sx={{m: 1}}/>
          <Button
            data-cy="addMedication"
            onClick={handleAddMedication}
            variant="contained" startIcon={<Add />}>
            {t("tecvayli.addMedication")}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} hidden={!TecvayliValues.isMonotherapy}>
        <TextField
          label={t("tecvayli.comments")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => dispatch(onChangeTecvayliValue({formData: {comments: event.target.value}}))}
          value={TecvayliValues.comments || ""}
          data-cy="comments"
        />

      </Grid>

      <Grid
        hidden={
          !(
            TecvayliValues.isOtherIndication
          )
        }
        item xs={12}>

        <Grid item xs={12} >
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
          >
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("tecvayli.warning.offlabel")}</Typography>
          </Stack>
        </Grid>
        <Box sx={{m: 3}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("preTreatment")}
          value={TecvayliValues.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeTecvayliValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          data-cy="patientCondition"
          style={{width: "100%"}}
          label={t("patientCondition")}
          InputLabelProps={{shrink: true}}
          value={TecvayliValues.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeTecvayliValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          data-cy="reasonsAlternativeTherapiesFailed"
          label={t("reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={TecvayliValues.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeTecvayliValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("prognosis")}
          data-cy="prognosis"
          InputLabelProps={{shrink: true}}
          value={TecvayliValues.prognosis }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeTecvayliValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

      </Grid>


    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(TecvayliForm);
