import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import {
  get,
  post,
} from "store/api";
import {onReceivePatientEditData, onClearPatientState} from "./forms/patient/patientSlice";

import {formatReceivedBrukinsaData,
  formatReceivedDefaultMedicineData,
  formatReceivedDiagnosisData,
  formatReceivedDoctorData,
  formatReceivedDosisPlanData,
  formatReceivedQuviviqData,
  formatReceivedCalquenceData,
  formatReceivedImfinziData,
  formatReceivedImjudoData,
  formatReceivedTagrissoData,
  formatReceivedLynparzaData,
  formatReceivedIbranceData,
  formatReceivedSomatulineData,
  formatReceivedCabometyxData,
  formatReceivedSpravatoData,
  formatReceivedTecvayliData,
  formatReceivedTalveyData,
  formatReceivedDarzalexData,
  formatReceivedImbruvicaData,
  formatReceivedEnhertuData,
  formatReceivedMedData,
  formatReceivedPatientData,
  formatReceivedPraluentData,
  formatReceivedTreatmentPlanData,
  formatspecialMed,
} from "./utils";
import {onReceiveDoctorEditData, onClearDoctorState} from "./forms/doctor/doctorSlice";

import {onReceiveDiagnosisEditData, onClearDiagnosisState} from "./forms/med/Diagnosis/diagnosisSlice";
import {
  onReceiveDosisPlanEditData,
  onClearDosisState,
  onCombinedMedication,
} from "./forms/med/Dosis/dosisPlanSlice";

import {
  onReceiveMedEditData,
  onChangeMedicineValue,
  onChangeLastLoaded,
  onChangeSubstancesValue,
  onChangeHtmlValue,
  onChangeShortageValue,
  onChangeSameSubstanceValue,
  onChangeInteractionsValue,
  onClearMedicineState,
  onChangeMedicationValues,
  onChangeKVVLegalState,
} from "./forms/med/medicine/medicineSlice";

import {onReceiveTreatmentPlanEditData, onClearTreatmentState} from "./forms/med/Treatment/treatmentPlanSlice";
import {onReceiveSpecialMedBrukinsaEditData, onClearBrukinsaState} from "./forms/med/AdditionalInformation/forms/BrukinsaForm/BrukinsaSlice";
import {onReceiveSpecialMedEnhertuEditData, onClearEnhertuState} from "./forms/med/AdditionalInformation/forms/EnhertuForm/EnhertuSlice";
import {onReceiveSpecialMedQuviviqEditData, onClearQuviviqState} from "./forms/med/AdditionalInformation/forms/QuviviqForm/QuviviqSlice";
import {onReceiveSpecialMedCalquenceEditData, onClearCalquenceState} from "./forms/med/AdditionalInformation/forms/Calquence/CalquenceSlice";
import {onReceiveSpecialMedImfinziEditData, onClearImfinziState} from "./forms/med/AdditionalInformation/forms/Imfinzi/ImfinziSlice";
import {onReceiveSpecialMedImjudoEditData, onClearImjudoState} from "./forms/med/AdditionalInformation/forms/Imjudo/ImjudoSlice";
import {onReceiveSpecialMedTagrissoEditData, onClearTagrissoState} from "./forms/med/AdditionalInformation/forms/Tagrisso/TagrissoSlice";
import {onReceiveSpecialMedLynparzaEditData, onClearLynparzaState} from "./forms/med/AdditionalInformation/forms/Lynparza/LynparzaSlice";
import {onReceiveSpecialMedIbranceEditData, onClearIbranceState} from "./forms/med/AdditionalInformation/forms/Ibrance/IbranceSlice";
import {onReceiveSpecialMedSomatulineEditData, onClearSomatulineState} from "./forms/med/AdditionalInformation/forms/Somatuline/SomatulineSlice";
import {onReceiveSpecialMedCabometyxEditData, onClearCabometyxState} from "./forms/med/AdditionalInformation/forms/Cabometyx/CabometyxSlice";
import {onReceiveSpecialMedSpravatoEditData, onClearSpravatoState} from "./forms/med/AdditionalInformation/forms/Spravato/SpravatoSlice";
import {onReceiveSpecialMedTecvayliEditData, onClearTecvayliState} from "./forms/med/AdditionalInformation/forms/Tecvayli/TecvayliSlice";
import {onReceiveSpecialMedTalveyEditData, onClearTalveyState} from "./forms/med/AdditionalInformation/forms/Talvey/TalveySlice";
import {onReceiveSpecialMedDarzalexEditData, onClearDarzalexState} from "./forms/med/AdditionalInformation/forms/Darzalex/DarzalexSlice";
import {onReceiveSpecialMedImbruvicaEditData, onClearImbruvicaState} from "./forms/med/AdditionalInformation/forms/Imbruvica/ImbruvicaSlice";
import {onReceiveSpecialMedPraluentEditData, onClearPraluentState} from "./forms/med/AdditionalInformation/forms/PraluentForm/praluentSlice";
import {getAvailableAttachments, processFilesAndPubmed} from "store/middleware/newRequestThunk";
import {onReceiveDefaultMedicineEditData, onClearAdditionalInfoState} from "./forms/med/AdditionalInformation/forms/DefaultMedicineForm/defaultMedicineSlice";


import {fetchAdditionalDrugInfo} from "store/middleware/masterdataThunk";
import {onClearSpecialMedState, onReceiveSpecialMed} from "./forms/med/AdditionalInformation/specialMedSlice";
import {resetMasterdataStore, onReceivedBagData, onReceivedPubMedData} from "store/features/masterdataSlice";
import {
  cleanDocumentState,
}
  from "store/features/newRequestSlice";

import {
  onAddStudy,
  onRemoveStudy,
} from "store/features/newRequestSlice";

import {displayToastError, displayToastSuccess} from "../../store/features/errorSlice";
import i18n from "./../../i18n";

export interface IWizardState {
  aiAdvice:string;
  loading: {
    postRequest: boolean,
    getRequest: boolean,
    sendToInsurance: boolean,
    editRequestLoad: boolean,
    loadingAiHint: boolean,
  },
  currentSection:string;
  currentForm:string;
  isFormVisible: {
    patient: boolean,
    doctor: boolean,
    medicine:boolean,
    diagnosis:boolean,
    dosis:boolean,
    treatment:boolean,
    additionalInformation:boolean,
    kn:boolean,
    uploadStudy:boolean,
    uploadDocument:boolean,
    overview:boolean,
  },
  sections:Array<{
    id:string;
    label:string;
    icon:any;
    forms: Array<{
      id:string;
      label:string
    }>;
}>
}


export const setCombinedMedication = createAsyncThunk(
    "wizard/setCombinedMedication",
    async (data: { medication: string; }, thunkAPI) => {
      if (data.medication === "") {
        await thunkAPI.dispatch(onCombinedMedication(undefined));
      } else {
        const medications = data.medication.split(",");
        await thunkAPI.dispatch(onCombinedMedication(medications));
      }
    });

export const setMedication = createAsyncThunk(
    "wizard/setMedication",
    async (data: { medication: string; }, thunkAPI) => {
      if (data.medication === "") {
        await thunkAPI.dispatch(onChangeMedicineValue({field: "required_drug", value: ""}));
      } else {
        await thunkAPI.dispatch(onChangeMedicineValue({field: "required_drug", value: data.medication}));
      }
    });

export const removeStudy = createAsyncThunk(
    "wizard/removeStudy",
    async (data: {}, thunkAPI) => {
      thunkAPI.dispatch(onRemoveStudy(undefined));
    });


export const getPreselectedStudy = createAsyncThunk(
    "wizard/getPreselectedStudy",
    async (
        data: {}, thunkAPI) => {
      const state = <RootState>thunkAPI.getState();
      const wizardData = state.wizard;
      const drug = wizardData.medicine.medForm.formData.required_drug;
      const diagnosis = wizardData.medicine.diagnosisForm?.formData.diagnosis;
      console.debug("getPreselectedStudy diagnosis", diagnosis);
      console.debug("getPreselectedStudy drug", drug);
      if (diagnosis?.length > 0 && drug?.length >0) {
        let additionalInfo;
        if (drug?.toLowerCase().includes("enhertu")) {
          // enhertu,
          // NONE: 0,
          // ENHERTU_HER_LOW: 1,
          // ENHERTU_HER_POS: 2,
          additionalInfo = wizardData.medicine.additionalInformation.specialMed.formData.special_med_additional_information;
        } else if (drug?.toLowerCase().includes("brukinsa")) {
          // brukinsa
          // true/false value
          additionalInfo = wizardData.medicine.additionalInformation.brukinsaCllForm?.formData?.previously_treated;
        } else if (drug?.toLowerCase().includes("quviviq")) {
          additionalInfo = wizardData.medicine.additionalInformation.specialMed.formData.special_med_additional_information;
        } else if (drug?.toLowerCase().includes("calquence")) {
          additionalInfo = 0;
        } else if (drug?.toLowerCase().includes("imfinzi")) {
          additionalInfo = 0;
        } else if (drug?.toLowerCase().includes("imjudo")) {
          additionalInfo = 0;
        } else if (drug?.toLowerCase().includes("imbruvica")) {
          additionalInfo = wizardData.medicine.additionalInformation.specialMed.formData.special_med_additional_information;
        } else {
          additionalInfo = "";
        }
        console.debug("[MedicineForm] [pre-selected studies] check for pre-selected studies. This is called if either diagnosis or mediciation is changed.");

        await get(`studies/preselected?drug=${drug}&diagnosis=${diagnosis}&additional_info=${additionalInfo}`)
            .then(async (res)=>{
              if (res?.search_criteria) {
                const json = await get(`pubMed/${encodeURIComponent(res?.search_criteria)}`);
                if (json.success === true) {
                  await thunkAPI.dispatch(onReceivedPubMedData(json.searchresult));
                  if (json.searchresult.length > 0) {
                    await thunkAPI.dispatch(onAddStudy(json.searchresult[0]));
                    thunkAPI.dispatch(onReceivedPubMedData([]));
                  }
                }
              } else {
                thunkAPI.dispatch(onRemoveStudy(undefined));
              }
            })
            .catch((error) => {
              thunkAPI.dispatch(displayToastError(i18n.t("error.request.unknown")));
              console.error(error);
            });
      }
    });


export const setStudyById = createAsyncThunk(
    "wizard/getStudyById",
    async (
        data:{studyId:string;}, thunkAPI) => {
      console.debug("setStudyById", data.studyId);
      if (data.studyId) {
        const json = await get(`pubMed/${encodeURIComponent(data.studyId)}`);
        if (json.success === true) {
          await thunkAPI.dispatch(onReceivedPubMedData(json.searchresult));
          if (json.searchresult.length > 0) {
            await thunkAPI.dispatch(onAddStudy(json.searchresult[0]));
            thunkAPI.dispatch(onReceivedPubMedData([]));
          }
        }
      }
    });

const cleanSpecialMedication = async ( thunkAPI) => {
  return Promise.all([
    thunkAPI.dispatch(onClearBrukinsaState()),
    thunkAPI.dispatch(onClearEnhertuState()),
    thunkAPI.dispatch(onClearQuviviqState()),
    thunkAPI.dispatch(onClearCalquenceState()),
    thunkAPI.dispatch(onClearImfinziState()),
    thunkAPI.dispatch(onClearImjudoState()),
    thunkAPI.dispatch(onClearTagrissoState()),
    thunkAPI.dispatch(onClearLynparzaState()),
    thunkAPI.dispatch(onClearIbranceState()),
    thunkAPI.dispatch(onClearSomatulineState()),
    thunkAPI.dispatch(onClearCabometyxState()),
    thunkAPI.dispatch(onClearSpravatoState()),
    thunkAPI.dispatch(onClearTecvayliState()),
    thunkAPI.dispatch(onClearTalveyState()),
    thunkAPI.dispatch(onClearDarzalexState()),
    thunkAPI.dispatch(onClearImbruvicaState()),
    thunkAPI.dispatch(onClearPraluentState()),
    thunkAPI.dispatch(onClearSpecialMedState()),
    thunkAPI.dispatch(onChangeMedicineValue({field: "kn_id", value: ""})),
  ]);
};

export const cleanStore = createAsyncThunk(
    "wizard/clean",
    async (
        data: {}, thunkAPI) => {
      console.log("cleanStore", data || "");
      Promise.all([
        cleanSpecialMedication(thunkAPI),
        thunkAPI.dispatch(onClearPatientState()),
        thunkAPI.dispatch(onClearAdditionalInfoState()),
        thunkAPI.dispatch(onClearDiagnosisState()),
        thunkAPI.dispatch(onClearDosisState()),
        thunkAPI.dispatch(onClearTreatmentState()),
        thunkAPI.dispatch(resetMasterdataStore()),
        thunkAPI.dispatch(cleanDocumentState({})),
        thunkAPI.dispatch(onClearDoctorState()),
        thunkAPI.dispatch(onClearMedicineState()),
        thunkAPI.dispatch(onRemoveStudy(undefined)),
      ]);
    });

export const cleanStoreWithoutDoctorAndMedication = createAsyncThunk(
    "wizard/clean",
    async (
        data: {}, thunkAPI) => {
      console.log("cleanStore", data || "");
      Promise.all([
        cleanSpecialMedication(thunkAPI),
        thunkAPI.dispatch(onClearPatientState()),
        thunkAPI.dispatch(onClearAdditionalInfoState()),
        thunkAPI.dispatch(onClearDiagnosisState()),
        thunkAPI.dispatch(onClearDosisState()),
        thunkAPI.dispatch(onClearTreatmentState()),
        thunkAPI.dispatch(cleanDocumentState({})),
        thunkAPI.dispatch(onRemoveStudy(undefined)),
      ]);
    });
export const cleanStoreWithoutDoctor = createAsyncThunk(
    "wizard/clean",
    async (
        data: {}, thunkAPI) => {
      console.log("cleanStore", data || "");
      Promise.all([
        cleanSpecialMedication(thunkAPI),
        thunkAPI.dispatch(onClearPatientState()),
        thunkAPI.dispatch(onClearAdditionalInfoState()),
        thunkAPI.dispatch(onClearDiagnosisState()),
        thunkAPI.dispatch(onClearDosisState()),
        thunkAPI.dispatch(onClearTreatmentState()),
        thunkAPI.dispatch(resetMasterdataStore()),
        thunkAPI.dispatch(cleanDocumentState({})),
        thunkAPI.dispatch(onClearMedicineState()),
        thunkAPI.dispatch(onRemoveStudy(undefined)),
      ]);
    });

export const cleanSpecialMedicationStore = createAsyncThunk(
    "wizard/clean",
    async (
        data: {}, thunkAPI) => {
      console.log("cleanStore", data || "");
      Promise.all([
        cleanSpecialMedication(thunkAPI),
        thunkAPI.dispatch(onClearSpecialMedState()),
        thunkAPI.dispatch(onClearDosisState()),
        thunkAPI.dispatch(onRemoveStudy(undefined)),
      ]).then(()=>{
        thunkAPI.dispatch(onChangeLastLoaded());
      });
    });


export const saveRequestBodyToSession = createAsyncThunk(
    "wizard/saveRequestBodyToSesseion",
    async (data:{}, thunkAPI) => {
      const currentLang=i18n.language.toUpperCase();
      const {requestBody, documents} = getRequestObject(thunkAPI, {
        language: currentLang,
        requestId: "",
      });
      sessionStorage.setItem("requestBody", JSON.stringify(requestBody));
      sessionStorage.setItem("requestdocuments", JSON.stringify(documents));
    });

export const saveRequestBodyFromSession = createAsyncThunk(
    "wizard/saveRequestBodyFromSession",
    async (data:{}, thunkAPI) => {
      try {
        let requestBody = sessionStorage.getItem("requestBody" ) || "{}";
        let koguRequestDocuments = sessionStorage.getItem("requestdocuments") || "{}";
        requestBody = JSON.parse(requestBody);
        koguRequestDocuments = JSON.parse(koguRequestDocuments);
        sessionStorage.removeItem("requestBody" );
        sessionStorage.removeItem("requestdocuments");
        const url = "requests/new/";
        return await post(url, requestBody).then(async (res)=>{
          const requestId = res[0].newRequestID;
          await thunkAPI.dispatch(processFilesAndPubmed(requestId, koguRequestDocuments));
          return res;
        });
      } catch (error) {
        thunkAPI.dispatch(displayToastError(i18n.t("error.request.unknown")));
        console.error("wizard/saveRequestBodyFromSession", error);
      }
    });

export const textToJsonKoguRequestObject = createAsyncThunk(
    "wizard/textToJsonKogu",
    async (data:{
      message:string;
    }, thunkAPI) => {
      try {
        const currentLang=i18n.language.toUpperCase();
        await thunkAPI.dispatch(onSaveOrEditPending({value: true}));
        // await thunkAPI.dispatch(cleanStore({ }));
        const url = "robot/texttojsonkogurequestobject/";
        return await post(url, {
          prompt: data,
        }).then(async (res)=>{
          await loadDataFromJSON(res, thunkAPI);
          if (res?.required_drug) {
            await thunkAPI.dispatch(fetchFirstSearchResultFromBackend({
              searchstring: res?.required_drug,
              currentlang: currentLang,
            }));
          }
        });
      } catch (error) {
        console.error("wizard/sentKoguRequestObjectToAI", error);
        thunkAPI.dispatch(displayToastError(i18n.t("error.request.unknown")));
      } finally {
        await thunkAPI.dispatch(onSaveOrEditPending({value: false}));
      }
    });

export const aiAdviceKoguRequestObject = createAsyncThunk(
    "wizard/aiAdviceKoguRequestObject",
    async (data:{}, thunkAPI) => {
      try {
        await thunkAPI.dispatch(onLoadAIAdvice({value: true}));
        console.debug("aiAdviceKoguRequestObject");
        const url = "robot/aiadvicekogurequestobject/";
        const currentLang=i18n.language.toUpperCase();
        const {requestBody} = getRequestObject(thunkAPI, {
          language: currentLang,
          requestId: "",
        });
        if (!requestBody.patient_date_of_birth) {
          thunkAPI.dispatch(displayToastError(i18n.t("error.ai.patient_date_of_birth")));
          await thunkAPI.dispatch(onAiAdviceChange({value: i18n.t("error.ai.patient_date_of_birth")}));
        } else if (!requestBody.diagnosis) {
          thunkAPI.dispatch(displayToastError(i18n.t("error.ai.diagnosis")));
          await thunkAPI.dispatch(onAiAdviceChange({value: i18n.t("error.ai.diagnosis")}));
        } else if (!requestBody.required_drug) {
          thunkAPI.dispatch(displayToastError(i18n.t("error.ai.required_drug")));
          await thunkAPI.dispatch(onAiAdviceChange({value: i18n.t("error.ai.required_drug")}));
        } else {
          return await post(url, {
            prompt: {
              patient_date_of_birth: requestBody.patient_date_of_birth,
              patient_gender: requestBody.patient_gender,
              diagnosis: requestBody.diagnosis,
              remarks_diagnosis: requestBody.remarks_diagnosis,
              dosis: requestBody.dosis,
              required_drug: requestBody.required_drug,
              combined_drug: requestBody.combined_drug,
              correspondence_language: currentLang,
              pretreatment: requestBody.pretreatment,
              therapy_alternatives: requestBody.therapy_alternatives,
              patient_condition: requestBody.patient_condition,
            },
          }).then(async (res)=>{
            console.debug("aiAdviceKoguRequestObject", res.answer);
            await thunkAPI.dispatch(onAiAdviceChange({value: res.answer}));
            if (res.studies && res.studies.length > 0) {
              thunkAPI.dispatch(displayToastSuccess(i18n.t("success.ai.studies_found")));
              await thunkAPI.dispatch(onReceivedPubMedData(res.studies));
            }
          });
        }
      } catch (error) {
        console.error("wizard/aiAdviceKoguRequestObject", error);
        await thunkAPI.dispatch(onAiAdviceChange({value: ""}));
        thunkAPI.dispatch(displayToastError(i18n.t("error.request.unknown")));
      } finally {
        await thunkAPI.dispatch(onLoadAIAdvice({value: false}));
      }
    });

export const saveOrEdit = createAsyncThunk(
    "wizard/saveOrEdit",
    async (data:{language:string; requestId:string}, thunkAPI) => {
      console.debug( "wizard/saveOrEdit");
      const {requestBody, documents} = getRequestObject(thunkAPI, data);
      try {
        await thunkAPI.dispatch(onSaveOrEditPending({value: true}));
        const url = `requests/${data.requestId?"edit":"new"}/`;
        return await post(url, requestBody).then(async (res)=>{
          const requestId=data.requestId?data.requestId:res[0].newRequestID;
          await thunkAPI.dispatch(processFilesAndPubmed(requestId, documents));
          return res;
        });
      } catch (error) {
        thunkAPI.dispatch(displayToastError(i18n.t("error.request.unknown")));
        console.error("wizard/saveOrEdit", error);
      } finally {
        await thunkAPI.dispatch(onSaveOrEditPending({value: false}));
      }
    });


export const fetchFirstSearchResultFromBackend = createAsyncThunk(
    "wizard/fetchFirstSearchResultFromBackend",
    async (data:{
      searchstring:string
      currentlang:string
    }, thunkAPI) => {
      try {
        console.log("fetchFirstSearchResultFromBackend", data.searchstring);
        const state = <RootState>thunkAPI.getState();
        const wizardData = state.wizard;
        const treatmentPlanForm = wizardData.medicine.treatmentPlanForm.formData;
        await thunkAPI.dispatch(onClearMedicineState());
        await thunkAPI.dispatch(onSaveOrEditPending({value: true}));
        await get("masterdata/search/" + btoa(encodeURIComponent(data.searchstring)))
            .then(async (drugdata) =>{
              console.debug("fetchFirstSearchResultFromBackend", drugdata[0]);
              console.debug("treatmentPlanForm", treatmentPlanForm);

              await thunkAPI.dispatch(onChangeMedicationValues({value: drugdata[0]}));
              await thunkAPI.dispatch(onChangeKVVLegalState(
                  {
                    limitatio_approval: treatmentPlanForm?.limitatio_approval,
                    specialist_info: treatmentPlanForm?.specialist_info,
                  },
              ));
            });
      } catch (error) {
        thunkAPI.dispatch(displayToastError(i18n.t("error.request.unknown")));
        console.error(error);
      } finally {
        await thunkAPI.dispatch(onSaveOrEditPending({value: false}));
      }
    });


export const fetchRequestData = createAsyncThunk(
    "wizard/fetchRequestData",
    async (data:{id:string}, thunkAPI) => {
      await thunkAPI.dispatch(onSaveOrEditPending({value: true}));
      await thunkAPI.dispatch(cleanStore(
          {
          },
      ));
      console.debug("fetchRequestData", data.id);
      await get("requests/" + data.id)
          .then(async (json) => {
            try {
              // we load the default values for the medication first.
              const request = json[0];
              await loadEditData(request, thunkAPI);


              console.log("postRequest done");
              await Promise.all([
                thunkAPI.dispatch(getAvailableAttachments(data.id)),
              ]);
              console.log("postRequest documents done");

              thunkAPI.dispatch(onSaveOrEditPending({value: false}));
            } catch (err) {
              thunkAPI.dispatch(onSaveOrEditPending({value: false}));
              console.error("error on edit", err);
              console.error("error on edit", err, " ", json[0]?.med_data);
            }
          }).catch((err) => {
            thunkAPI.dispatch(onSaveOrEditPending({value: false}));
          });
    },

);
export const sendRequestToInsurance = createAsyncThunk(
    "wizard/sendRequestToInsurance",
    async (data:{comment:any, requestId:string, currentLang:string, isEdit:any}, thunkAPI) => {
      const isEdit=data.isEdit;
      const comment=data.comment;
      try {
        thunkAPI.dispatch(onSaveOrEditPending({value: true}));
        await post(`requests/${data.requestId}/sendToInsurance`, {isEdit, comment})
            .finally(()=>console.log("sendRequestToInsurance done"));
      } catch (err) {
        thunkAPI.dispatch(displayToastError(i18n.t("error.request.unknown")));
      } finally {
        thunkAPI.dispatch(onSaveOrEditPending({value: false}));
      }
    },
);


const initialState:IWizardState = {
  aiAdvice: "",
  loading: {
    postRequest: false,
    getRequest: false,
    sendToInsurance: false,
    editRequestLoad: false,
    loadingAiHint: false,
  },
  currentSection: "information",
  currentForm: "patient",
  isFormVisible: {
    patient: false,
    doctor: false,
    medicine: false,
    diagnosis: false,
    dosis: false,
    treatment: false,
    additionalInformation: false,
    kn: false,
    uploadStudy: false,
    uploadDocument: false,
    overview: false,
  },

  sections: [
    {
      id: "diagnosis",
      label: "wizard.medRequest.title",
      icon: LooksOneIcon,
      forms: [
        {
          id: "diagnosis",
          label: "wizard.diagnosis.title",
        },
        {
          id: "medicine",
          label: "wizard.medicine.title",
        },
        {
          id: "additionalInformation",
          label: "wizard.more.information.title",
        },
        {
          id: "treatment",
          label: "wizard.treatment.plan.title",
        },
        {
          id: "dosis",
          label: "wizard.dosis.plan.title",
        },
        {
          id: "kn",
          label: "wizard.kn.title",
        },
      ],
    },
    {
      id: "upload",
      label: "wizard.upload.title",
      icon: LooksTwoIcon,
      forms: [{
        id: "uploadStudy",
        label: "wizard.search.studies.title",
      },
      {
        id: "uploadDocument",
        label: "wizard.upload.documents.title",
      },
      ],
    },
    {
      id: "patient",
      label: "wizard.information.title",
      icon: Looks3Icon,
      forms: [{
        id: "patient",
        label: "wizard.patient.title",
      },
      {
        id: "doctor",
        label: "wizard.doctor.title",
      },
      ],
    },
    {
      id: "overview",
      label: "wizard.overview.title",
      icon: Looks4Icon,
      forms: [{
        id: "overview",
        label: "wizard.overview.title",
      }],
    },

  ],
};
const wizardSlice = createSlice({
  name: "wizardModel",
  initialState,
  reducers: {
    onAiAdviceChange: (state, {payload}:PayloadAction<{ value: string; }>) => {
      state.aiAdvice = payload.value;
    },
    onFormChange: (state, {payload}:PayloadAction<{section:string;form:string;}>)=>{
      state.currentForm = payload.form;
      state.currentSection = payload.section;
    },
    onViewChange: (state, {payload}: PayloadAction<{ field: string; value: boolean; }>) => {
      state.isFormVisible[payload.field] = payload.value;
    },
    onSaveOrEditPending: (state, {payload}: PayloadAction<{ value: boolean; }>) => {
      state.loading.editRequestLoad = payload.value;
    },
    onLoadAIAdvice: (state, {payload}: PayloadAction<{ value: boolean; }>) => {
      state.loading.loadingAiHint = payload.value;
    },
  },
});

export const {
  onFormChange,
  onViewChange,
  onSaveOrEditPending,
  onLoadAIAdvice,
  onAiAdviceChange,
} = wizardSlice.actions;

export default wizardSlice.reducer;


export const selectSections = (state:RootState) => state.wizard.wizardModel.sections;
export const selectIsLoading = (state:RootState) => state.wizard.wizardModel.loading;

export const selectCurrentForms = (state:RootState) =>
  state.wizard?.wizardModel?.sections?.find((e)=>e.id===state.wizard.wizardModel.currentSection)?.forms || [];
export const getAllWizardForms = (state:RootState) =>
  state.wizard?.wizardModel?.sections.flatMap((section)=>section.forms.map((form)=>form));
export const selectCurrentWizardModel = (state:RootState):{currentSection:string;currentForm:string;} => ({
  currentSection: state.wizard.wizardModel.currentSection,
  currentForm: state.wizard.wizardModel.currentForm});
export const isFormVisible = (state: RootState) => state.wizard.wizardModel.isFormVisible;


export const selectVisibleSection=(state:RootState)=>{
  const visibleState=state.wizard.wizardModel.isFormVisible;
  if (visibleState.diagnosis||visibleState.dosis||
    visibleState.medicine||visibleState.treatment||visibleState.additionalInformation) {
    return "diagnosis";
  } else if (visibleState.uploadDocument||visibleState.uploadStudy) {
    return "upload";
  } else if (visibleState.doctor||visibleState.patient) {
    return "patient";
  } else {
    return "overview";
  };
};
async function loadEditData(request: any, thunkAPI) {
  let drugdata: any = null;
  console.debug("edit updated drugdata", request);
  drugdata = request?.med_data?.required_drug &&
    await get("masterdata/search/" +
      btoa(encodeURIComponent(request?.med_data.required_drug)));
  console.debug("edit updated drugdata", drugdata);
  if (Array.isArray(drugdata)) {
    await thunkAPI.dispatch(onReceivedBagData({data: drugdata}));
    await thunkAPI.dispatch(
        fetchAdditionalDrugInfo(request.med_data.required_drug,
            request.diagnosis),
    );
    // Load masterdata
    await thunkAPI.dispatch(onChangeMedicineValue({field: "required_drug", value: drugdata[0]?.article}));
    await thunkAPI.dispatch(onChangeHtmlValue({
      field: "professionalPatientInformationInHtmlFormat",
      value: drugdata[0]?.professionalPatientInformationInHtmlFormat,
    }));

    await thunkAPI.dispatch(onChangeHtmlValue({
      field: "limtree",
      value: drugdata[0]?.limtree,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({field: "required_drug_name", value: drugdata[0]?.productname}));
    await thunkAPI.dispatch(onChangeShortageValue({value: drugdata[0]?.medicationShortageWarning}));

    await thunkAPI.dispatch(onChangeMedicineValue({
      field: "pharma_company_name",
      value: drugdata[0]?.prtnoPharmaCompany,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({
      field: "article_prdno",
      value: drugdata[0]?.article_prdno,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({field: "pharma_email", value: drugdata[0]?.pharmaCompanyMailAdress}));
    await thunkAPI.dispatch(onChangeSubstancesValue({value: drugdata[0]?.substances}));
    await thunkAPI.dispatch(onChangeSameSubstanceValue({
      field: "sameSubstanceMeds",
      value: drugdata[0]?.sameSubstanceMedications,
    }));
    await thunkAPI.dispatch(onChangeInteractionsValue({
      field: "productsInteraction",
      value: drugdata[0]?.productsInteraction,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({
      field: "pharma_company_name",
      value: drugdata[0]?.prtnoPharmaCompany,
    }));

    await thunkAPI.dispatch(onChangeMedicationValues({value: drugdata[0]}));
  }

  // Load Edit Data
  console.debug("Load Edit Data", request);

  await Promise.all([
    thunkAPI.dispatch(onChangeMedicineValue({field: "kn_id", value: request.kn_id ?? ""})),
    thunkAPI.dispatch(onReceivePatientEditData(formatReceivedPatientData(request))),
    thunkAPI.dispatch(onReceiveDoctorEditData(formatReceivedDoctorData(request))),
    thunkAPI.dispatch(onReceiveDiagnosisEditData(formatReceivedDiagnosisData(request))),
    thunkAPI.dispatch(onReceiveDosisPlanEditData(formatReceivedDosisPlanData(request))),
    thunkAPI.dispatch(onReceiveTreatmentPlanEditData(formatReceivedTreatmentPlanData(request))),
    thunkAPI.dispatch(onReceiveMedEditData(formatReceivedMedData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedPraluentEditData(formatReceivedPraluentData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedBrukinsaEditData(formatReceivedBrukinsaData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedEnhertuEditData(formatReceivedEnhertuData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedQuviviqEditData(formatReceivedQuviviqData(request))),
    thunkAPI.dispatch(onReceiveDefaultMedicineEditData(formatReceivedDefaultMedicineData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedCalquenceEditData(formatReceivedCalquenceData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedImfinziEditData(formatReceivedImfinziData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedImjudoEditData(formatReceivedImjudoData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedTagrissoEditData(formatReceivedTagrissoData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedLynparzaEditData(formatReceivedLynparzaData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedIbranceEditData(formatReceivedIbranceData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedSomatulineEditData(formatReceivedSomatulineData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedCabometyxEditData(formatReceivedCabometyxData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedSpravatoEditData(formatReceivedSpravatoData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedTecvayliEditData(formatReceivedTecvayliData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedTalveyEditData(formatReceivedTalveyData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedDarzalexEditData(formatReceivedDarzalexData(request))),
    thunkAPI.dispatch(onReceiveSpecialMedImbruvicaEditData(formatReceivedImbruvicaData(request))),
    thunkAPI.dispatch(onReceiveSpecialMed(formatspecialMed(request))),
  ]);
}

async function loadDataFromJSON(request: any, thunkAPI) {
  let drugdata: any = null;
  drugdata = request?.med_data?.required_drug &&
    await get("masterdata/search/" +
      btoa(encodeURIComponent(request?.med_data.required_drug)) + "/" );
  console.debug("edit updated drugdata", drugdata);
  if (Array.isArray(drugdata)) {
    await thunkAPI.dispatch(onReceivedBagData({data: drugdata}));
    await thunkAPI.dispatch(
        fetchAdditionalDrugInfo(request.med_data.required_drug,
            request.diagnosis, request.correspondence_language),
    );
    // Load masterdata
    await thunkAPI.dispatch(onChangeMedicineValue({field: "required_drug", value: drugdata[0]?.article}));
    await thunkAPI.dispatch(onChangeHtmlValue({
      field: "professionalPatientInformationInHtmlFormat",
      value: drugdata[0]?.professionalPatientInformationInHtmlFormat,
    }));

    await thunkAPI.dispatch(onChangeHtmlValue({
      field: "limtree",
      value: drugdata[0]?.limtree,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({field: "required_drug_name", value: drugdata[0]?.productname}));
    await thunkAPI.dispatch(onChangeShortageValue({value: drugdata[0]?.medicationShortageWarning}));

    await thunkAPI.dispatch(onChangeMedicineValue({
      field: "pharma_company_name",
      value: drugdata[0]?.prtnoPharmaCompany,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({
      field: "article_prdno",
      value: drugdata[0]?.article_prdno,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({field: "pharma_email", value: drugdata[0]?.pharmaCompanyMailAdress}));
    await thunkAPI.dispatch(onChangeSubstancesValue({value: drugdata[0]?.substances}));
    await thunkAPI.dispatch(onChangeSameSubstanceValue({
      field: "sameSubstanceMeds",
      value: drugdata[0]?.sameSubstanceMedications,
    }));
    await thunkAPI.dispatch(onChangeInteractionsValue({
      field: "productsInteraction",
      value: drugdata[0]?.productsInteraction,
    }));

    await thunkAPI.dispatch(onChangeMedicineValue({
      field: "pharma_company_name",
      value: drugdata[0]?.prtnoPharmaCompany,
    }));

    await thunkAPI.dispatch(onChangeMedicationValues({value: drugdata[0]}));
  }
  await Promise.all([
    thunkAPI.dispatch(onChangeMedicineValue({field: "kn_id", value: request.kn_id ?? ""})),
    thunkAPI.dispatch(onReceivePatientEditData(formatReceivedPatientData(request))),
    thunkAPI.dispatch(onReceiveDiagnosisEditData(formatReceivedDiagnosisData(request))),
    thunkAPI.dispatch(onReceiveDosisPlanEditData(formatReceivedDosisPlanData(request))),
    thunkAPI.dispatch(onReceiveTreatmentPlanEditData(formatReceivedTreatmentPlanData(request))),
    thunkAPI.dispatch(onReceiveMedEditData(formatReceivedMedData(request))),
  ]);
}

function getRequestObject(thunkAPI, data: { language: string; requestId: string; }) {
  const state = <RootState>thunkAPI.getState();
  const wizardData = state.wizard;
  console.debug("saveOrEdit");
  const documents = {
    studiesMarkedForRemoval: state.singleRequest.studiesMarkedForRemoval,
    filesMarkedForRemoval: state.singleRequest.filesMarkedForRemoval,
    localFiles: state.singleRequest.localFiles,
    downloadedFiles: state.singleRequest.downloadedFiles,
    pubmedStudy: state.singleRequest.pubmedStudy,
  };

  const buildNewRequestBody = (wizardData: RootState["wizard"]) => ({
    ...wizardData.patient.formData,
    ...wizardData.doctor.formData,
    "doctor_country": wizardData.doctor.formData.doctor_country.code,
    "patient_country": wizardData.patient.formData.patient_country?.code,
    "patient_insurance_company": wizardData.patient.formData.patient_insurance?.DESCR1,
    "patient_insurance_company_gln": wizardData.patient.formData.patient_insurance?.GLN,
    "kogu_type": "med",
    ...wizardData.medicine.diagnosisForm.formData,
    ...wizardData.medicine.dosisPlanForm.formData,
    ...wizardData.medicine.treatmentPlanForm.formData,
    ...wizardData.medicine.additionalInformation.defaultMedicineForm.formData,
    "required_drug": wizardData.medicine.medForm.formData.required_drug,
    "request_type": wizardData.medicine.medForm.formData.request_type,
    "pharma_email": wizardData.medicine.medForm.formData.pharma_email,
    "pharma_company_name": wizardData.medicine.medForm.formData.pharma_company_name,
    "required_drug_name": wizardData.medicine.medForm.formData.required_drug_name,
    "diagnosis": wizardData.medicine.diagnosisForm.formData.diagnosis,
    "combined_drug": wizardData.medicine.dosisPlanForm.formData.combined_drug?.join(", "),
    "kn_id": wizardData.medicine.medForm.formData.kn_id,
    "med_data": {
      ...wizardData.medicine.diagnosisForm.formData,
      ...wizardData.medicine.dosisPlanForm.formData,
      ...wizardData.medicine.treatmentPlanForm.formData,
      ...wizardData.medicine.medForm.formData,
      ...wizardData.medicine.additionalInformation.defaultMedicineForm.formData,
      "article_prdno": wizardData.medicine.medForm.formData.article_prdno,
      "kn_id": wizardData.medicine.medForm.formData.kn_id,
      "special_med_type": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type,
      "special_med_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_data,
      "special_med_additional_information": wizardData.medicine.additionalInformation.specialMed.formData.special_med_additional_information,
      "praluent_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Praluent" ? {
        ...wizardData.medicine.additionalInformation.praluentForm.formData,
        ldlCWert_during: wizardData.medicine.additionalInformation.praluentForm.formData.ldlCWert_during.toString(),
        ldlCWert_before: wizardData.medicine.additionalInformation.praluentForm.formData.ldlCWert_before.toString(),
      } : {},
      "brukinsa_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Brukinsa" ? wizardData.medicine.additionalInformation.brukinsaForm.formData : {},
      "brukinsa_cll_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "BrukinsaCll" ? wizardData.medicine.additionalInformation.brukinsaCllForm.formData : {},
      "enhertu_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Enhertu" ? wizardData.medicine.additionalInformation.enhertuForm.formData : {},
      "quviviq_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Quviviq" ? wizardData.medicine.additionalInformation.quviviqForm.formData : {},
      "calquence_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Calquence" ? wizardData.medicine.additionalInformation.calquenceForm.formData : {},
      "imfinzi_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Imfinzi" ? wizardData.medicine.additionalInformation.imfinziForm.formData : {},
      "imbruvica_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Imbruvica" ? wizardData.medicine.additionalInformation.imbruvicaForm.formData : {},
      "imjudo_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Imjudo" ? wizardData.medicine.additionalInformation.imjudoForm.formData : {},
      "tagrisso_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Tagrisso" ? wizardData.medicine.additionalInformation.tagrissoForm.formData : {},
      "lynparza_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Lynparza" ? wizardData.medicine.additionalInformation.lynparzaForm.formData : {},
      "ibrance_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Ibrance" ? wizardData.medicine.additionalInformation.ibranceForm.formData : {},
      "somatuline_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Somatuline" ? wizardData.medicine.additionalInformation.somatulineForm.formData : {},
      "cabometyx_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Cabometyx" ? wizardData.medicine.additionalInformation.cabometyxForm.formData : {},
      "spravato_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Spravato" ? wizardData.medicine.additionalInformation.spravatoForm.formData : {},
      "tecvayli_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Tecvayli" ? wizardData.medicine.additionalInformation.tecvayliForm.formData : {},
      "talvey_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Talvey" ? wizardData.medicine.additionalInformation.talveyForm.formData : {},
      "darzalex_data": wizardData.medicine.additionalInformation.specialMed.formData.special_med_type === "Darzalex" ? wizardData.medicine.additionalInformation.darzalexForm.formData : {},
      "diagnosis": wizardData.medicine.diagnosisForm.formData.diagnosis,
      "combined_drug": wizardData.medicine.dosisPlanForm.formData.combined_drug?.join(", "),
      "limitatio_approval": wizardData.medicine.treatmentPlanForm.formData.limitatio_approval?.toString(),
      "specialist_info": wizardData.medicine.treatmentPlanForm.formData.specialist_info?.toString(),
      "specialist_details": wizardData.medicine.treatmentPlanForm.formData.specialist_details?.toString(),
    },
    "migel_data": {},
    "ambstat_data": {},
    "reha_data": {},
    "transport_data": {},
    "breathing_device_data": {},
    "correspondence_language": data.language,
    "request_status": "DRAFT",
    ...data.requestId && {request_id: parseInt(data.requestId)},
  });
  const requestBody = buildNewRequestBody(wizardData);
  console.debug("saveOrEdit requestBody", requestBody );
  console.debug("saveOrEdit requestBody", documents );


  return {requestBody, documents};
}

