import {de, frCH, itCH} from "date-fns/esm/locale";
import {useState, useEffect, useMemo} from "react";
export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
  );

  useEffect(() => {
    if (ref !== undefined) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, observer]);
  return isIntersecting;
}

export const calendarLanguage=(currentLang)=>{
  if (currentLang==="FR") {
    return frCH;
  }
  if (currentLang==="IT") {
    return itCH;
  }
  return de;
};

export const calendarMask=(currentLang)=>{
  if (currentLang==="FR") {
    return "__.__.____";
  }
  return "__.__.____";
};


