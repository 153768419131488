import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  IconButton,
  Button,
  FormGroup,
  Checkbox,
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
  selectMedicineValues,
} from "../../../medicine/medicineSlice";

import {
  onChangeDarzalexValue,
  selectDarzalexValues,
  onAddMedication,
  onRemoveMedication,
} from "./DarzalexSlice";

import DrugPlanForm from "./DrugPlanForm";

const DarzalexForm = (props) => {
  // usestate for variant, numeric value:
  const [variant, setVariant] = useState(0);

  const {t, i18n}=useTranslation();
  const dispatch = UseAppDispatch();
  const DarzalexValues = UseAppSelector(selectDarzalexValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const isDarzalexSC = medicineValues?.pharmacode === "7782364";
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const currentLang=i18n.language.toUpperCase();


  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);


  const handleAddMedication = () => {
    dispatch(onAddMedication({name: "", start: "", stop: "", comment: ""})); // Add an empty medication item
  };

  const handleRemoveMedication = (index) => {
    dispatch(onRemoveMedication(index));
  };

  const handleMedicationChange = (index, field, value) => {
    const updatedMedications = DarzalexValues.medications.map((medication, idx) =>
      idx === index ? {...medication, [field]: value} : medication,
    );
    dispatch(onChangeDarzalexValue({formData: {medications: updatedMedications}}));
  };


  useEffect(() => {
    console.debug("DarzalexValues changed", DarzalexValues);

    // Variant 1
    if (
      (DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
      DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
      DarzalexValues.therapy_isMonotherapy && // Monotherapy
      DarzalexValues.monotherapy_Patient_1_3 // Therapielinie 1-3
    ) {
      setVariant(1);
      return;
    }
    // Variant 2
    if (
      (DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
      DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
      DarzalexValues.therapy_isMonotherapy && // Monotherapy
      DarzalexValues.monotherapy_Patient_4 // Therapielinie 4+
    ) {
      setVariant(2);
      return;
    }
    // Variant 3
    if (
      (DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
      DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
      DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
      DarzalexValues.combinationTherapy_Patient_1 && // Linie 1
      DarzalexValues.line1_bortezomibMelphalanPrednisone && // Lenalidomid & Dexamethason
      DarzalexValues.line1_notEligibleForAutologousStemCellTransplant === true
    ) {
      setVariant(3);
      return;
    }

    // Variant 4
    if (
      (DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
      DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
      DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
      DarzalexValues.combinationTherapy_Patient_1 && // Linie 1
      DarzalexValues.line1_lenalidomideDexamethasone && // Bortezomib & Melphalan & Prednison
      DarzalexValues.line1_notEligibleForAutologousStemCellTransplant === true
    ) {
      setVariant(4);
      return;
    }

    // Variant 5
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
        DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
        DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
        DarzalexValues.combinationTherapy_Patient_2 && // Linie 2
        DarzalexValues.combination_lenalidomideDexamethasoneDRd &&
        DarzalexValues.line2_nonRefractoryNonRelapsedCD38Treatment === true
    ) {
      setVariant(5);
      return;
    }

    // Variant 6
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
        DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
        DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
        DarzalexValues.combinationTherapy_Patient_2 && // Linie 2
        DarzalexValues.combination_bortezomibDexamethasoneDVd &&
        DarzalexValues.line2_nonRefractoryNonRelapsedCD38Treatment === true
    ) {
      setVariant(6);
      return;
    }

    // Variant 7
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
            DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
            DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
            DarzalexValues.combinationTherapy_Patient_2 && // Linie 2
            DarzalexValues.combination_pomalidomideDexamethasoneDPd
    ) {
      setVariant(7);
      return;
    }

    // Variant 8
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
            DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
            DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
            DarzalexValues.combinationTherapy_Patient_2 && // Linie 2
            DarzalexValues.combination_carfilzomibDexamethasoneDKd &&
            DarzalexValues.line2_nonRefractoryNonRelapsedCD38Treatment === true
    ) {
      setVariant(8);
      return;
    }

    // Variant 9
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
            DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
            DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
            DarzalexValues.combinationTherapy_Patient_2 && // Linie 2
            DarzalexValues.combination_otherCombination &&
            DarzalexValues.line2_nonRefractoryNonRelapsedCD38Treatment === true
    ) {
      setVariant(9);
      return;
    }

    // Variant 10
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
        DarzalexValues.indications_untreatedAlAmyloidosis &&
        DarzalexValues.prescriptions_isTrainingCenter === false
    ) {
      setVariant(10);
      return;
    }

    // Variant 11
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
        DarzalexValues.indications_otherIndicationToBeTreated // Other Indication
    ) {
      setVariant(11);
      return;
    }


    // Variant 12
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
            DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
            DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
            DarzalexValues.combinationTherapy_Patient_2 && // Linie 2
            DarzalexValues.line2_nonRefractoryNonRelapsedCD38Treatment === false &&
            (
              DarzalexValues.combination_lenalidomideDexamethasoneDRd||
              DarzalexValues.combination_bortezomibDexamethasoneDVd||
              DarzalexValues.combination_pomalidomideDexamethasoneDPd||
              DarzalexValues.combination_carfilzomibDexamethasoneDKd||
              DarzalexValues.combination_otherCombination
            )
    ) {
      setVariant(12);
      return;
    }

    // Variant 13
    if (
      (DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
      DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
      DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
      DarzalexValues.combinationTherapy_Patient_1 && // Linie 1
      (
        DarzalexValues.line1_lenalidomideDexamethasone ||
        DarzalexValues.line1_bortezomibMelphalanPrednisone
      ) && // Bortezomib & Melphalan & Prednison
      DarzalexValues.line1_notEligibleForAutologousStemCellTransplant == false
    ) {
      setVariant(13);
      return;
    }

    // Variant 15
    if (
      ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
            DarzalexValues.indications_untreatedAlAmyloidosis &&
            DarzalexValues.prescriptions_isTrainingCenter
    ) {
      setVariant(15);
      return;
    }

    setVariant(0);
  }, [
    DarzalexValues,
  ]);


  useEffect(() => {
    console.debug("DarzalexValues changed", DarzalexValues);
    switch (variant) {
      case 2:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.01"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.01"}));
        break;
      case 3:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.03"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.03"}));
        break;
      case 4:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.08"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.08"}));
        break;
      case 5:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.02"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.02"}));
        break;
      case 6:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.05"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.05"}));
        break;
      case 7:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.06"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.06"}));
        break;
      case 8:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.07"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.07"}));
        dispatch(onChangeDosisValue({field: "dosis", value: t("darzalex.dose")}));

        break;
      case 9:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.02"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.02"}));
        break;
      case 10:
        dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", true);
        handleValueChangeKVV("specialist_info", true);
        DarzalexValues.options_isIv && dispatch(onChangeMedicineValue({field: "kn_id", value: "20589.04"}));
        DarzalexValues.options_isIc && dispatch(onChangeMedicineValue({field: "kn_id", value: "21150.04"}));
        break;
      case 15:
        dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", true);
        break;
      case 0:
      case 1:
      case 12:
      case 13:
      default:
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        handleValueChangeKVV("limitatio_approval", false);
        handleValueChangeKVV("specialist_info", false);
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        break;
    }
  }, [
    variant,
  ]);


  // DarzalexValues.line1_

  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>{t("darzalex.options_isIvIsC")}</FormLabel>
            <RadioGroup
              aria-labelledby="darzalex-radio-buttons-group-form-type"
              name="darzalex-radio-buttons-group-form-type"
              onChange={(event, value) => {
                const isIv = value === "options_isIv";
                dispatch(onChangeDarzalexValue({formData: {options_isIv: isIv, options_isIc: !isIv}}));
              }}
            >
              {[
                {value: "options_isIv", checked: DarzalexValues.options_isIv},
                {value: "options_isIc", checked: DarzalexValues.options_isIc},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`darzalex.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Stack
            hidden= {
              !(
                (isDarzalexSC === true) && (DarzalexValues.options_isIc === false) ||
                (isDarzalexSC === false) && (DarzalexValues.options_isIc === true)
              )
            }
            direction="row" alignItems="left" gap={1}>
            <WarningIcon color="primary" />
            <Typography>{t("darzalex.warning.IvSc")}</Typography>
          </Stack>
        </Grid>


        <Grid item xs={12}
          hidden={
            !(DarzalexValues.options_isIc === true ||
            DarzalexValues.options_isIv === true)
          }
        >
          <FormControl>
            <FormLabel>{t("darzalex.indications")}</FormLabel>
            <RadioGroup
              aria-labelledby="darzalex-radio-buttons-group-indications"
              name="darzalex-radio-buttons-group-indications"
              onChange={(event, value) => {
                dispatch(
                    onChangeDarzalexValue({
                      formData: {
                        indications_isMultiplesMyelom: value === "indications_isMultiplesMyelom",
                        indications_untreatedAlAmyloidosis: value === "indications_untreatedAlAmyloidosis",
                        indications_otherIndicationToBeTreated: value === "indications_otherIndicationToBeTreated",
                      },
                    }),
                );
              }}
            >
              {[
                {value: "indications_isMultiplesMyelom", checked: DarzalexValues.indications_isMultiplesMyelom},
                ...(DarzalexValues.options_isIc ?
                [{value: "indications_untreatedAlAmyloidosis", checked: DarzalexValues.indications_untreatedAlAmyloidosis}] :
              []),
                {value: "indications_otherIndicationToBeTreated", checked: DarzalexValues.indications_otherIndicationToBeTreated},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`darzalex.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>


        <Grid item xs={12} hidden={
          DarzalexValues.indications_isMultiplesMyelom === false
        }
        >
          <FormControl>
            <FormLabel>{t("darzalex.therapy")}</FormLabel>
            <RadioGroup
              aria-labelledby="darzalex-radio-buttons-group-therapy"
              name="darzalex-radio-buttons-group-therapy"
              onChange={(event, value) => {
                dispatch(
                    onChangeDarzalexValue({
                      formData: {
                        therapy_isMonotherapy: value === "therapy_isMonotherapy",
                        therapy_isCombinationTherapy: value === "therapy_isCombinationTherapy",
                      },
                    }),
                );
              }}
            >
              {[
                {value: "therapy_isMonotherapy", checked: DarzalexValues.therapy_isMonotherapy},
                {value: "therapy_isCombinationTherapy", checked: DarzalexValues.therapy_isCombinationTherapy},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`darzalex.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>


        <Grid item xs={12}
          hidden={
            DarzalexValues.indications_isMultiplesMyelom === false ||
            DarzalexValues.therapy_isMonotherapy === false
          }

        >
          <FormControl>
            <FormLabel>{t("darzalex.therapielinie")}</FormLabel>
            <RadioGroup
              aria-labelledby="darzalex-radio-buttons-group-monotherapy"
              name="darzalex-radio-buttons-group-monotherapy"
              onChange={(event, value) => {
                dispatch(
                    onChangeDarzalexValue({
                      formData: {
                        monotherapy_Patient_4: value === "monotherapy_Patient_4",
                        monotherapy_Patient_1_3: value === "monotherapy_Patient_1_3",
                      },
                    }),
                );
              }}
            >
              {[
                {value: "monotherapy_Patient_4", checked: DarzalexValues.monotherapy_Patient_4},
                {value: "monotherapy_Patient_1_3", checked: DarzalexValues.monotherapy_Patient_1_3},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`darzalex.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>


        <Grid item xs={12}
          hidden={
            DarzalexValues.indications_isMultiplesMyelom === false ||
            DarzalexValues.therapy_isCombinationTherapy === false
          }
        >
          <FormControl>
            <FormLabel>{t("darzalex.therapielinieCombination")}</FormLabel>
            <RadioGroup
              aria-labelledby="darzalex-radio-buttons-group-combination-therapy"
              name="darzalex-radio-buttons-group-combination-therapy"
              onChange={(event, value) => {
                dispatch(
                    onChangeDarzalexValue({
                      formData: {
                        combinationTherapy_Patient_1: value === "combinationTherapy_Patient_1",
                        combinationTherapy_Patient_2: value === "combinationTherapy_Patient_2",
                      },
                    }),
                );
                if (value === "combinationTherapy_Patient_2") {
                  dispatch(
                      onChangeDarzalexValue({
                        formData: {
                          line2_nonRefractoryNonRelapsedCD38Treatment: event.target.checked,
                        },
                      }),
                  );
                }
                if (value === "combinationTherapy_Patient_1") {
                  dispatch(
                      onChangeDarzalexValue({
                        formData: {
                          line1_notEligibleForAutologousStemCellTransplant: event.target.checked,
                        },
                      }),
                  );
                }
              }}
            >
              {[
                {value: "combinationTherapy_Patient_1", checked: DarzalexValues.combinationTherapy_Patient_1},
                {value: "combinationTherapy_Patient_2", checked: DarzalexValues.combinationTherapy_Patient_2},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`darzalex.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>


        <Grid item xs={12}
          hidden={
            DarzalexValues.indications_isMultiplesMyelom === false ||
            DarzalexValues.therapy_isCombinationTherapy === false ||
            DarzalexValues.combinationTherapy_Patient_2 === true
          }
        >

          <FormControl>
            {/* Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={DarzalexValues.line1_notEligibleForAutologousStemCellTransplant}
                  onChange={(event) =>
                    dispatch(
                        onChangeDarzalexValue({
                          formData: {
                            line1_notEligibleForAutologousStemCellTransplant: event.target.checked,
                          },
                        }),
                    )
                  }
                  data-cy="line1_notEligibleForAutologousStemCellTransplant"
                />
              }
              label={t("darzalex.line1_notEligibleForAutologousStemCellTransplant")}
            />
          </FormControl >
          {/* Text Field for Justification */}
          <Grid item xs={12}>
            <TextField
              label={t("darzalex.line1_justification")}
              variant="outlined"
              style={{width: "100%"}}
              fullWidth
              data-cy="line1_justification"
              value={DarzalexValues.line1_justification}
              onChange={(event) =>
                dispatch(
                    onChangeDarzalexValue({
                      formData: {line1_justification: event.target.value},
                    }),
                )
              }

            />
          </Grid>
          <FormControl>
            <Box sx={{m: 1}} />
            <Typography>{t("darzalex.line1_therapy")}</Typography>
            {/* Radio Options */}
            <RadioGroup
              aria-labelledby="darzalex-radio-buttons-group-line1-options"
              name="darzalex-radio-buttons-group-line1-options"
              onChange={(event, value) => {
                dispatch(
                    onChangeDarzalexValue({
                      formData: {
                        line1_bortezomibMelphalanPrednisone: value === "line1_bortezomibMelphalanPrednisone",
                        line1_lenalidomideDexamethasone: value === "line1_lenalidomideDexamethasone",
                      },
                    }),
                );
              }}
            >
              {[
                {value: "line1_bortezomibMelphalanPrednisone", checked: DarzalexValues.line1_bortezomibMelphalanPrednisone},
                {value: "line1_lenalidomideDexamethasone", checked: DarzalexValues.line1_lenalidomideDexamethasone},
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio checked={option.checked} data-cy={option.value} />}
                  label={t(`darzalex.${option.value}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Box sx={{m: 1}} hidden={DarzalexValues.line1_bortezomibMelphalanPrednisone} />
          <Typography hidden={DarzalexValues.line1_bortezomibMelphalanPrednisone}>
            {t("darzalex.line1_additionalInfo")}
          </Typography>
          <Box sx={{m: 1}} hidden={DarzalexValues.line1_bortezomibMelphalanPrednisone} />
          <Stack
            hidden={
              DarzalexValues.line1_bortezomibMelphalanPrednisone
            }
            direction="row" alignItems="left" gap={1}>
            <WarningIcon color="primary" />
            <Typography>{t("darzalex.warning.line1_additionalInfo.yellow")}</Typography>
          </Stack>
        </Grid>


        {
          // CD 38 Treatment
        }

        <Grid item xs={12}
          hidden={
            !(
              ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
              DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
              DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
              DarzalexValues.combinationTherapy_Patient_2 // Linie 2
            )
          }

        >
          <FormControlLabel
            control={
              <Checkbox
                checked={DarzalexValues.line2_nonRefractoryNonRelapsedCD38Treatment}
                onChange={(event) =>
                  dispatch(
                      onChangeDarzalexValue({
                        formData: {
                          line2_nonRefractoryNonRelapsedCD38Treatment: event.target.checked,
                        },
                      }),
                  )
                }
                data-cy="line2_nonRefractoryNonRelapsedCD38Treatment"
              />
            }
            label={t("darzalex.line2_nonRefractoryNonRelapsedCD38Treatment")}
          />
        </Grid>

        {
          // Medication Block Array
        }

        <Grid
          hidden={
            !(variant === 2) &&
            // Variant 4
            !(
              ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
              DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
              DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
              DarzalexValues.combinationTherapy_Patient_2 // Linie 2
            )
          }
          item xs={12}>
          <Typography hidden={!(variant === 2)}>
            {t("darzalex.warning.monotherapyPatient4")}
          </Typography>
          <Typography hidden={
            // Variant 4
            !(
              ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
              DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
              DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
              DarzalexValues.combinationTherapy_Patient_2 // Linie 2
            )
          }>
            {t("darzalex.warning.variant4")}
          </Typography>


          <Box sx={{m: 3}} />
          <Stack hidden={!(variant === 2)}
            direction="row" alignItems="left" gap={1}>
            <WarningIcon color="primary" />
            <Typography>{t("darzalex.warning.monotherapyPatient4.yellow")}</Typography>
          </Stack>
        </Grid>
        <Grid
          hidden={
            !(variant === 2) &&
            // Variant 4
            !(
              ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
                DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
                DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
                DarzalexValues.combinationTherapy_Patient_2 // Linie 2
            )

          }
          item xs={12}>

          <Typography>{t("darzalex.drugplan.info")}</Typography>


          {DarzalexValues.medications.map((medication, index) => (
            <Grid key={index} container spacing={2}>
              <Grid item xs={10}>
                <Typography>{t("darzalex.drugplan.therapy")}: {index+1}</Typography>
                <DrugPlanForm
                  data-cy="medicationName"
                  label={t("darzalex.medicationName")}
                  value={medication.name}
                  onChange={(value) => handleMedicationChange(index, "name", value)}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                <IconButton
                  data-cy="removeMedication"
                  onClick={() => handleRemoveMedication(index)}
                  color="secondary"
                >
                  <Delete />
                </IconButton>
              </Grid>
              {[
                {label: "start", type: "month", dataCy: "start-date", value: medication.start},
                {label: "stop", type: "month", dataCy: "stop-date", value: medication.stop},
              ].map((field) => (
                <Grid item xs={6} key={field.label}>
                  <DatePicker
                    label={t(`darzalex.${field.label}`)}
                    views={["year", "month"]}
                    inputFormat="MM/yyyy"
                    value={field.value || null}
                    onChange={(newValue) => handleMedicationChange(index, field.label, newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        data-cy={field.dataCy}
                        InputLabelProps={{shrink: true}}
                      />
                    )}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextField
                  label={t("darzalex.comment")}
                  variant="outlined"
                  fullWidth
                  data-cy="medication-comment"
                  value={medication.comment}
                  onChange={(event) => handleMedicationChange(index, "comment", event.target.value)}
                />
              </Grid>
              <Box sx={{m: 1}}/>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}
          hidden={
            !(variant === 2) &&
            !(
              ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
              DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
              DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
              DarzalexValues.combinationTherapy_Patient_2 // Linie 2
            )
          }
        >
          <Button
            data-cy="addMedication"
            onClick={handleAddMedication}
            variant="contained" startIcon={<Add />}>
            {t("talvey.addMedication")}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}
        hidden={
          // Variant 4
          !(
            ( DarzalexValues.options_isIv || DarzalexValues.options_isIc ) && // Iv or Ic
              DarzalexValues.indications_isMultiplesMyelom && // Multiples Myelom
              DarzalexValues.therapy_isCombinationTherapy && // Combination Therapy
              DarzalexValues.combinationTherapy_Patient_2 // Linie 2
          )
        }
      >
        <Box sx={{m: 2}} />
        <FormControl component="fieldset">
          <FormLabel>{t("darzalex.combinationTherapy")}</FormLabel>
          <RadioGroup
            aria-labelledby="darzalex-radio-buttons-group-combination-options"
            name="darzalex-radio-buttons-group-combination-options"
            onChange={(event, value) => {
              dispatch(
                  onChangeDarzalexValue({
                    formData: {
                      combination_lenalidomideDexamethasoneDRd: value === "combination_lenalidomideDexamethasoneDRd",
                      combination_bortezomibDexamethasoneDVd: value === "combination_bortezomibDexamethasoneDVd",
                      combination_pomalidomideDexamethasoneDPd: value === "combination_pomalidomideDexamethasoneDPd",
                      combination_carfilzomibDexamethasoneDKd: value === "combination_carfilzomibDexamethasoneDKd",
                      combination_otherCombination: value === "combination_otherCombination",
                    },
                  }),
              );
            }}
          >
            {[
              {value: "combination_lenalidomideDexamethasoneDRd", checked: DarzalexValues.combination_lenalidomideDexamethasoneDRd},
              {value: "combination_bortezomibDexamethasoneDVd", checked: DarzalexValues.combination_bortezomibDexamethasoneDVd},
              {value: "combination_pomalidomideDexamethasoneDPd", checked: DarzalexValues.combination_pomalidomideDexamethasoneDPd},
              {value: "combination_carfilzomibDexamethasoneDKd", checked: DarzalexValues.combination_carfilzomibDexamethasoneDKd},
              {value: "combination_otherCombination", checked: DarzalexValues.combination_otherCombination},
            ]
                .filter((option) => !(DarzalexValues.options_isIc === false && option.value === "combination_pomalidomideDexamethasoneDPd"))
                .map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio checked={option.checked} data-cy={option.value} />}
                    label={t(`darzalex.${option.value}`)}
                  />
                ))}
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} hidden={(!(variant === 9))} >
        <Stack direction="row" alignItems="left" gap={1}>
          <WarningIcon color="primary" />
          <Typography>{t("darzalex.warning.combination_otherCombinationRemarks")}</Typography>
        </Stack>


        <TextField
          label={t("darzalex.combination_otherCombinationRemarks")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) =>
            dispatch(
                onChangeDarzalexValue({
                  formData: {combination_otherCombinationRemarks: event.target.value},
                }),
            )
          }
          value={DarzalexValues.combination_otherCombinationRemarks || ""}
          data-cy="combination_otherCombinationRemarks"
        />
      </Grid>


      <Grid item xs={12}
        hidden={
          !((DarzalexValues.options_isIv || DarzalexValues.options_isIc ) &&
          DarzalexValues.indications_untreatedAlAmyloidosis)
        }

      >
        <FormControl>
          <FormLabel>{t("darzalex.prescriptions")}</FormLabel>
          <FormGroup
            onChange={(event) => {
              const target = event.target as HTMLInputElement;
              const {name, checked} = target;
              dispatch(
                  onChangeDarzalexValue({
                    formData: {
                      ...DarzalexValues,
                      [name]: checked,
                    },
                  }),
              );
            }}
          >
            {[
              {value: "prescriptions_combinationTherapyBCD", checked: DarzalexValues.prescriptions_combinationTherapyBCD},
              {value: "prescriptions_firstPrescription", checked: DarzalexValues.prescriptions_firstPrescription},
              {value: "prescriptions_isTrainingCenter", checked: DarzalexValues.prescriptions_isTrainingCenter},
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={option.checked}
                    onChange={(event) => {
                      dispatch(
                          onChangeDarzalexValue({
                            formData: {
                              ...DarzalexValues,
                              [option.value]: event.target.checked,
                            },
                          }),
                      );
                    }}
                    name={option.value}
                    data-cy={option.value}
                  />
                }
                label={t(`darzalex.${option.value}`)}
              />
            ))}
          </FormGroup>
          <Typography>{t("darzalex.prescriptions_additionalInfo")}</Typography>
          <a href="http://www.siwf-register.ch" target="_blank" rel="noopener noreferrer">
            http://www.siwf-register.ch
          </a>
        </FormControl>
      </Grid>


      <Grid item xs={12}
        hidden={

          !(variant === 7)


        }
      >
        <FormControl component="fieldset"
        >
          <FormLabel>{t("darzalex.combinationPomalidomidDPd")}</FormLabel>

          {/* Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={DarzalexValues.combination_pomalidomid_noPriorPomalidomideTreatment}
                onChange={(event) =>
                  dispatch(
                      onChangeDarzalexValue({
                        formData: {
                          combination_pomalidomid_noPriorPomalidomideTreatment: event.target.checked,
                        },
                      }),
                  )
                }
                data-cy="combination_pomalidomid_noPriorPomalidomideTreatment"
              />
            }
            label={t("darzalex.combination_pomalidomid_noPriorPomalidomideTreatment")}
          />

          {/* Radio Options */}
          <RadioGroup
            aria-labelledby="darzalex-radio-buttons-group-combination-pomalidomid-options"
            name="darzalex-radio-buttons-group-combination-pomalidomid-options"
            onChange={(event, value) => {
              dispatch(
                  onChangeDarzalexValue({
                    formData: {
                      combination_pomalidomid_priorPILenalidomideTherapyRefractory: value === "combination_pomalidomid_priorPILenalidomideTherapyRefractory",
                      combination_pomalidomid_twoPriorTherapiesWithPILenalidomide: value === "combination_pomalidomid_twoPriorTherapiesWithPILenalidomide",
                    },
                  }),
              );
            }}
          >
            {[
              {value: "combination_pomalidomid_priorPILenalidomideTherapyRefractory", checked: DarzalexValues.combination_pomalidomid_priorPILenalidomideTherapyRefractory},
              {value: "combination_pomalidomid_twoPriorTherapiesWithPILenalidomide", checked: DarzalexValues.combination_pomalidomid_twoPriorTherapiesWithPILenalidomide},
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio checked={option.checked} data-cy={option.value} />}
                label={t(`darzalex.${option.value}`)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>


      <Grid item xs={12}
        hidden={

          (
            !(variant === 3) &&
            !(variant === 4) &&
            !(variant === 5) &&
            !(variant === 6) &&
            !(variant === 7) &&
            !(variant === 8) &&
            !(variant === 9) &&
            !(variant === 10) &&
            !(variant === 15)
          )

        }
      >
        {

          <Typography hidden={variant !== 5}>
            {t("darzalex.comments.variant4")}
          </Typography>

        }

        <TextField
          label={t("darzalex.comments")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) =>
            dispatch(
                onChangeDarzalexValue({
                  formData: {comments: event.target.value},
                }),
            )
          }
          value={DarzalexValues.comments || ""}
          data-cy="comments"
        />
      </Grid>


      <Grid item xs={12}

        hidden={
          !(variant === 1) &&
          !(variant === 12) &&
          !(variant === 13)
        }
      >
        <Stack hidden={
          !(variant === 1) &&
          !(variant === 12) &&
          !(variant === 13)
        } direction="row" alignItems="left" gap={1}>
          <WarningIcon color="primary" />
          <Typography>{t("darzalex.warning.offlabel")}</Typography>
        </Stack>
        <Stack hidden={ !(variant === 11)} direction="row" alignItems="left" gap={1}>
          <WarningIcon color="primary" />
          <Typography>{t("darzalex.warning.otherindication")}</Typography>
        </Stack>
        <Box sx={{m: 3}} />
        {[
          {label: "preTreatment", dataCy: "preTreatment", value: DarzalexValues.preTreatment},
          {label: "patientCondition", dataCy: "patientCondition", value: DarzalexValues.patientCondition},
          {label: "reasonsAlternativeTherapiesFailed", dataCy: "reasonsAlternativeTherapiesFailed", value: DarzalexValues.reasonsAlternativeTherapiesFailed},
          {label: "prognosis", dataCy: "prognosis", value: DarzalexValues.prognosis},
        ].map((field, index) => (
          <React.Fragment key={field.label}>
            <TextField
              style={{width: "100%"}}
              label={t(field.label)}
              data-cy={field.dataCy}
              InputLabelProps={{shrink: true}}
              value={field.value}
              variant="outlined"
              maxRows={4}
              minRows={4}
              multiline
              onChange={(event) =>
                dispatch(
                    onChangeDarzalexValue({
                      [field.label]: event.target.value,
                    }),
                )
              }
            />
            <Box sx={{m: 3}} />
          </React.Fragment>
        ))}
      </Grid>

      {variant > 0 && <Grid item xs={12}>
        <Typography
          data-cy="variant"

        >{t("darzalex.variant")} {variant}</Typography>
      </Grid>}
    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(DarzalexForm);
